:root {
  --vh: 1vh; }

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden !important; }

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box; }
  *::after, *::before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    outline: 0; }
  *:active, *:focus, *:hover, *:visited {
    outline: 0;
    -webkit-tap-highlight-color: transparent; }

/* Webkit autofill background fix */
html body input:-webkit-autofill, html body input:-webkit-autofill:hover, html body input:-webkit-autofill:focus, html body input:-webkit-autofill:active, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, textarea:-webkit-autofill:active {
  transition: background-color 500000s ease-in-out 0s !important; }

@-webkit-keyframes autofill {
  to {
    color: inherit;
    background: transparent; } }

input:-webkit-autofill,
textarea:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both; }

button, input, hr, optgroup, select, textarea, p, h1, h2, h3, h4, h5, h6 {
  margin: 0; }

progress, sub, sup {
  vertical-align: baseline; }

pre, code {
  overflow: auto; }

embed, iframe, img, legend, object, pre, details {
  max-width: 100%;
  display: block;
  border: 0;
  outline: 0; }

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

a {
  background-color: transparent;
  text-decoration: none;
  color: inherit; }
  a:active, a:focus, a:hover, a:visited {
    color: unset;
    text-decoration: none; }

b, strong {
  font-weight: bold; }

code, kbd, samp, pre {
  font-family: monospace, monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

img {
  border: none;
  height: auto; }

button, select, input, textarea, label {
  text-transform: unset;
  border: none;
  outline: none;
  background: transparent; }

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button; }

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0; }

[type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
  outline: ButtonText dotted 1px; }

textarea {
  width: 100%;
  display: block;
  resize: vertical; }

[type=checkbox], [type=radio] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto; }

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

.hide, .hidden, [hidden] {
  display: none; }

body, button, input, optgroup, select, textarea {
  color: #404040;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1.5; }

cite, dfn, em, i {
  font-style: italic; }

blockquote {
  margin: 0 1.5em; }

address {
  margin: 0 0 1.5em; }

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: .9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  padding: 1.6em;
  color: #000; }

code, kbd, tt, var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: .9375rem; }

abbr, acronym {
  border-bottom: 1px dotted #666;
  cursor: help; }

ins, mark {
  background: #fff9c0;
  text-decoration: none; }

big {
  font-size: 125%; }

ol, ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

ol {
  list-style-type: decimal; }

li > ol, li > ul {
  margin: 0;
  padding: 0; }

dt {
  font-weight: 700; }

dd {
  margin: 0 1.5em 1.5em; }

figure {
  margin: 0; }

table {
  margin: 0;
  width: auto; }

input[type=color]:focus, input[type=date]:focus, input[type=datetime-local]:focus, input[type=datetime]:focus, input[type=email]:focus, input[type=month]:focus, input[type=number]:focus, input[type=password]:focus, input[type=range]:focus, input[type=search]:focus, input[type=tel]:focus, input[type=text]:focus, input[type=time]:focus, input[type=url]:focus, input[type=week]:focus, textarea:focus {
  color: inherit; }

a:active, a:focus, a:hover {
  color: inherit; }

input {
  appearance: none; }
  input[type=search] {
    appearance: textfield;
    outline: none; }
  input::-webkit-search-cancel-button {
    display: none;
    pointer-events: none;
    opacity: 0;
    visibility: hidden; }

*,
*::before,
*::after {
  box-sizing: border-box; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto; }

@media (max-width: 575px) {
  .container {
    width: 100%;
    max-width: 100%; } }

@media (min-width: 576px) {
  .container {
    width: 90%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .container {
    width: 725px;
    max-width: 100%; } }

@media (min-width: 992px) {
  .container {
    width: 920px;
    max-width: 100%; } }

@media (min-width: 1200px) {
  .container {
    width: 1000px;
    max-width: 100%; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

.hidden-xs-up {
  display: none !important; }

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important; } }

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important; } }

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important; } }

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important; } }

@media (max-width: 991px) {
  html .hidden-md-down {
    display: none !important; } }

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important; } }

@media (max-width: 1199px) {
  html .hidden-lg-down {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important; } }

.hidden-xl-down {
  display: none !important; }

.transition {
  transition: 0.3s all ease; }

body p {
  margin-top: 0;
  margin-bottom: 1em; }
  body p:last-child {
    margin-bottom: 0; }

body, body > p, body > span {
  margin: 0;
  padding: 0;
  color: #363636;
  font-family: 'Ubuntu';
  font-size: 17px;
  line-height: 22px;
  font-weight: 300;
  max-width: 100vw;
  overflow-x: hidden; }

.clearfix:before, .clearfix:after {
  content: "";
  display: table;
  clear: both; }

.overflow-hidden {
  overflow: hidden; }

.relative {
  position: relative; }

.aligncenter, .center {
  text-align: center; }

.alignleft, .left {
  text-align: left; }

.alignright, .right {
  text-align: right; }

.mb-no {
  margin-bottom: 0 !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.no-mt {
  margin-top: 0 !important; }

.content-btn, .wpforms-submit, .wpforms-form button[type=submit] {
  background: #2a639d;
  border: 2px solid #2a639d;
  color: #fff !important;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease;
  border-radius: 4px !important;
  cursor: pointer;
  padding: 15px; }

.btn-small {
  font-size: 15px;
  padding: 8px 20px; }

.content-btn:hover, .content-btn:active, .content-btn:focus, .wpforms-submit:hover, .wpforms-submit:active, .wpforms-submit:focus, div.wpforms-container-full .wpforms-form button[type=submit]:hover, div.wpforms-container-full .wpforms-form button[type=submit]:active {
  color: #fff;
  background-color: #bb8844;
  border: 2px solid #bb8844; }

.home-slider-wrapper .home-slider {
  position: relative;
  height: 100%; }
  .home-slider-wrapper .home-slider.arrows-off .home-slider-prev {
    display: none; }
  .home-slider-wrapper .home-slider.arrows-off .home-slider-next {
    display: none; }
  .home-slider-wrapper .home-slider .home-slider-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none; }
  .home-slider-wrapper .home-slider .home-slider-prev {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 70;
    border-radius: 100%; }
    .home-slider-wrapper .home-slider .home-slider-prev svg {
      transform: translateX(-1px); }
  .home-slider-wrapper .home-slider .home-slider-next {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 70;
    border-radius: 100%; }
    .home-slider-wrapper .home-slider .home-slider-next svg {
      transform: translateX(1px); }
  .home-slider-wrapper .home-slider .home-slider-prev svg, .home-slider-wrapper .home-slider .home-slider-next svg {
    height: 20px;
    width: auto;
    display: block; }
  .home-slider-wrapper .home-slider .home-slider-prev .arrow-wrap, .home-slider-wrapper .home-slider .home-slider-next .arrow-wrap {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center; }
  .home-slider-wrapper .home-slider .swiper-pagination-bullets {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 15px !important;
    left: 0 !important;
    right: 0 !important;
    top: auto !important;
    transform: none !important; }
    .home-slider-wrapper .home-slider .swiper-pagination-bullets .swiper-pagination-bullet {
      width: 12px;
      margin: 0 5px !important;
      height: 12px;
      transition: 0.3s all ease;
      opacity: 1; }
      .home-slider-wrapper .home-slider .swiper-pagination-bullets .swiper-pagination-bullet:hover, .home-slider-wrapper .home-slider .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
        opacity: 1; }
  .home-slider-wrapper .home-slider_slide {
    height: auto;
    overflow: hidden;
    position: relative; }
    .home-slider-wrapper .home-slider_slide .slide-thumbnail {
      backface-visibility: hidden;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      max-height: 100%;
      min-height: 100%;
      z-index: 0; }
      .home-slider-wrapper .home-slider_slide .slide-thumbnail .slide-bg {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%; }
      .home-slider-wrapper .home-slider_slide .slide-thumbnail .slide-video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%; }
    .home-slider-wrapper .home-slider_slide .iframe-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      pointer-events: none;
      overflow: hidden; }
      .home-slider-wrapper .home-slider_slide .iframe-wrapper iframe {
        width: 100vw;
        height: 56.25vw;
        /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 100vh;
        min-width: 177.77vh;
        /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .home-slider-wrapper .home-slider_slide .slider-content {
      box-sizing: border-box;
      position: relative;
      height: 100%;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 17px;
      line-height: 24px;
      z-index: 10;
      padding: 60px 0; }
      .home-slider-wrapper .home-slider_slide .slider-content p {
        margin-bottom: 1em; }
      .home-slider-wrapper .home-slider_slide .slider-content .slide-excerpt {
        margin-bottom: 10px;
        display: block;
        text-align: center;
        color: #fff; }
      .home-slider-wrapper .home-slider_slide .slider-content h1 {
        text-align: center;
        display: block;
        color: #fff;
        font-weight: 500;
        font-size: 42px;
        margin-top: 0;
        padding-top: 0;
        margin-bottom: 25px; }
      .home-slider-wrapper .home-slider_slide .slider-content .btn-wrap {
        margin-left: -8px;
        margin-right: -8px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center; }
        .home-slider-wrapper .home-slider_slide .slider-content .btn-wrap .slider-btn {
          margin-left: 8px;
          margin-right: 8px;
          margin-bottom: 8px; }
      .home-slider-wrapper .home-slider_slide .slider-content[data-align='left'] {
        align-items: flex-start;
        text-align: left; }
      .home-slider-wrapper .home-slider_slide .slider-content[data-align='center'] {
        align-items: center;
        text-align: center; }
      .home-slider-wrapper .home-slider_slide .slider-content[data-align='right'] {
        align-items: flex-end;
        text-align: right; }

@media (min-width: 1025px) {
  .home-slider-wrapper.slider-long {
    max-height: 955px !important;
    height: 955px !important; }
    .home-slider-wrapper.slider-long .home-slider_slide {
      height: 955px !important;
      max-height: 955px !important; }
  .home-slider-wrapper.slider-short {
    max-height: 655px !important;
    height: 655px !important; }
    .home-slider-wrapper.slider-short .home-slider_slide {
      height: 655px !important;
      max-height: 655px !important; } }

@media (max-width: 1024px) {
  .home-slider-wrapper {
    max-height: 600px;
    height: 600px; }
    .home-slider-wrapper .home-slider {
      height: 600px;
      max-height: 600px; }
      .home-slider-wrapper .home-slider_slide .slider-content h1 {
        font-size: 45px; } }

@media (max-width: 768px) {
  .home-slider-wrapper .home-slider_slide .slider-content {
    font-size: 11px;
    line-height: 14px;
    padding: 90px 0; }
    .home-slider-wrapper .home-slider_slide .slider-content h1 {
      font-size: 35px; } }

@media (max-width: 720px) {
  .home-slider-wrapper {
    min-height: 400px;
    height: 400px; }
    .home-slider-wrapper .home-slider {
      min-height: 400px;
      height: 400px; }
      .home-slider-wrapper .home-slider_slide .slider-content h1 {
        font-size: 30px;
        padding: 0 15px;
        margin-bottom: 25px; } }

.nav_style_d .home-slider-wrapper .home-slider_slide .slider-content {
  display: none; }

.section {
  position: relative; }
  .section .section-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
  .section .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 10; }

/* Welcome Section */
.section-welcome {
  padding-top: 30px !important;
  padding-bottom: 55px !important; }
  .section-welcome .btn-wrap {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center; }

/* Welcome Personal Section */
.section-welcome-personal {
  padding: 60px 0; }
  .section-welcome-personal .row.position-right {
    flex-direction: row-reverse; }
    .section-welcome-personal .row.position-right .col-image {
      padding-left: 30px; }
  .section-welcome-personal .row.position-left .col-image {
    padding-right: 30px; }
  .section-welcome-personal .col-image img {
    max-width: 100%;
    display: block; }
  .section-welcome-personal .content {
    margin-top: 40px;
    font-family: 'Open Sans', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.4; }
  .section-welcome-personal .btn-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px; }
  @media (max-width: 991px) {
    .section-welcome-personal .row {
      flex-direction: column !important; }
    .section-welcome-personal .row .col-image {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px !important; }
    .section-welcome-personal .row .col-content {
      padding-top: 35px;
      max-width: 540px;
      margin-left: auto;
      margin-right: auto; } }

.section-about {
  position: relative;
  width: 100%;
  padding: 125px 0; }
  .section-about .container {
    position: relative;
    z-index: 15;
    color: #fff; }
  .section-about .row {
    justify-content: center; }
  .section-about .section-about-title {
    margin-bottom: 20px;
    margin-top: 0; }
  .section-about .content {
    max-width: 100%;
    width: 100%; }
    .section-about .content p {
      max-width: 100%;
      white-space: normal;
      word-break: break-word; }
  .section-about .col-image img {
    max-width: 100%;
    display: block; }
  .section-about .btn-wrap {
    margin-top: 10px;
    display: flex; }
  @media (max-width: 767px) {
    .section-about {
      padding: 70px 0; }
      .section-about .col-image img {
        max-width: 260px;
        margin-left: auto;
        margin-right: auto; }
      .section-about .col-content {
        margin-top: 35px;
        margin-left: auto;
        margin-right: auto;
        max-width: 480px;
        text-align: center; }
      .section-about .col-content .btn-wrap {
        display: flex;
        justify-content: center; } }

.section-about-box {
  position: relative;
  width: 100%;
  padding: 160px 0; }
  .section-about-box .container {
    padding: 10px;
    position: relative;
    z-index: 15; }
  .section-about-box .row {
    padding: 40px;
    margin: 0; }
  .section-about-box .section-about-title {
    margin-bottom: 20px;
    margin-top: 0; }
  .section-about-box .content {
    max-width: 100%;
    width: 100%; }
    .section-about-box .content p {
      max-width: 100%;
      white-space: normal;
      word-break: break-word; }
  .section-about-box .col-image {
    padding: 0; }
    .section-about-box .col-image img {
      max-width: 100%;
      display: block; }
  .section-about-box .col-content {
    padding: 0;
    padding-left: 40px;
    font-size: 18px;
    line-height: 1.5; }
  .section-about-box .btn-wrap {
    margin-top: 12px;
    display: flex; }
  @media (max-width: 767px) {
    .section-about-box {
      padding: 70px 0; }
      .section-about-box .row {
        padding: 35px 20px; }
      .section-about-box .col-image img {
        max-width: 260px;
        margin-left: auto;
        margin-right: auto; }
      .section-about-box .col-content {
        margin-top: 35px;
        margin-left: auto;
        margin-right: auto;
        max-width: 480px;
        text-align: center;
        padding: 0; }
        .section-about-box .col-content .btn-wrap {
          display: flex;
          justify-content: center; } }

.section-staff-slider {
  margin: 0 auto;
  padding: 60px 0;
  position: relative; }
  .section-staff-slider .staff-slider-data[data-arrows='off'] .owl-nav {
    display: none; }
  .section-staff-slider .about-me {
    max-width: 1360px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 15; }
    .section-staff-slider .about-me .owl-prev, .section-staff-slider .about-me .owl-next {
      position: absolute;
      top: 50%;
      margin-top: -20px;
      font-size: 0;
      width: 40px;
      height: 40px;
      text-align: center;
      cursor: pointer; }
      .section-staff-slider .about-me .owl-prev:before, .section-staff-slider .about-me .owl-next:before {
        color: #486577;
        font-size: 30px; }
    .section-staff-slider .about-me .owl-prev {
      left: 20px; }
      .section-staff-slider .about-me .owl-prev:before {
        content: '\f053';
        font-family: 'fontAwesome'; }
    .section-staff-slider .about-me .owl-next {
      right: 20px; }
      .section-staff-slider .about-me .owl-next:before {
        content: '\f054';
        font-family: 'fontAwesome'; }
    .section-staff-slider .about-me .owl-nav[disabled] {
      display: none; }
      .section-staff-slider .about-me .owl-nav[disabled] .disabled {
        display: none; }
    .section-staff-slider .about-me .owl-carousel .owl-stage {
      display: flex; }
    .section-staff-slider .about-me .owl-carousel.one-item .owl-item {
      width: auto !important;
      max-width: 780px; }
    .section-staff-slider .about-me .staff-container.one-item .staff-box {
      padding-left: 380px; }
      .section-staff-slider .about-me .staff-container.one-item .staff-box .photo {
        float: left;
        margin-left: -380px; }
  .section-staff-slider .staff-section-title {
    text-align: center;
    display: block; }
  .section-staff-slider .content {
    width: 1200px;
    max-width: calc(100% - 100px);
    margin: 0 auto;
    margin-top: 40px;
    overflow-x: hidden; }
  .section-staff-slider .staff-box .wpforms-submit {
    display: inline-block; }
  .section-staff-slider .staff-box .photo {
    display: block;
    width: 80%;
    position: relative;
    margin: 0 auto 20px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    max-width: 250px;
    text-align: center;
    font-size: 19px;
    font-weight: 300;
    line-height: 1.4; }
    .section-staff-slider .staff-box .photo:after {
      display: block;
      content: "";
      padding-bottom: 100%; }
  .section-staff-slider .staff-box .staff-title {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
    display: block; }
  .section-staff-slider .staff-box p {
    text-align: center;
    margin-bottom: 15px; }
  .section-staff-slider .staff-box .btn-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px; }
  .section-staff-slider .staff-container.loading {
    display: flex;
    justify-content: center; }
    .section-staff-slider .staff-container.loading .staff-box {
      width: calc(100% / 3); }
  @media (max-width: 991px) {
    .section-staff-slider {
      padding: 35px 0; } }
  @media (max-width: 767px) {
    .section-staff-slider .about-me .owl-prev {
      left: 3px; }
    .section-staff-slider .about-me .owl-next {
      right: 3px; } }

.grid-section {
  background-color: #F3F3F3;
  padding: 60px 0; }
  .grid-section .container {
    z-index: 10;
    position: relative; }
  .grid-section .section-title {
    margin-bottom: 20px;
    padding: 0 15px;
    display: block;
    text-align: center; }
  .grid-section .grid-pages {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100% - 30px);
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0; }
    .grid-section .grid-pages .column {
      width: 50%; }
    .grid-section .grid-pages .page-item-wrap {
      width: 100%;
      position: relative;
      overflow: hidden; }
      .grid-section .grid-pages .page-item-wrap.square:after {
        content: "";
        padding-bottom: 85%;
        display: block; }
      .grid-section .grid-pages .page-item-wrap.rect:after {
        content: "";
        padding-bottom: 55%;
        display: block; }
      .grid-section .grid-pages .page-item-wrap .page-item {
        width: 100%;
        position: absolute;
        height: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        overflow: hidden; }
        .grid-section .grid-pages .page-item-wrap .page-item .image-overlay {
          width: 100%;
          position: absolute;
          object-fit: cover;
          object-position: center;
          font-family: 'object-fit: cover; object-position: center;';
          height: 100%;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          z-index: 4; }
        .grid-section .grid-pages .page-item-wrap .page-item .overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          transition: 0.3s all ease;
          z-index: 5; }
        .grid-section .grid-pages .page-item-wrap .page-item .content {
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          z-index: 6;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          transition: 0.3s all ease;
          padding-top: 58px; }
          .grid-section .grid-pages .page-item-wrap .page-item .content .page-title {
            display: block;
            line-height: 1.15;
            text-align: center;
            color: #fff;
            transition: 0.3s all ease;
            padding-left: 15px;
            padding-right: 15px;
            max-width: 95%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px; }
          .grid-section .grid-pages .page-item-wrap .page-item .content .other-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            transition: 0.3s all ease;
            opacity: 0;
            min-height: 110px;
            width: 100%; }
          .grid-section .grid-pages .page-item-wrap .page-item .content .page-description {
            margin-bottom: 15px;
            display: block;
            text-align: center;
            color: #fff;
            transition: 0.3s all ease;
            padding-left: 15px;
            padding-right: 15px;
            max-width: 90%;
            margin-left: auto;
            margin-right: auto; }
          .grid-section .grid-pages .page-item-wrap .page-item .content .btn {
            transition: 0.3s all ease;
            cursor: pointer; }
        .grid-section .grid-pages .page-item-wrap .page-item:hover .overlay {
          opacity: 1; }
        .grid-section .grid-pages .page-item-wrap .page-item:hover .content {
          padding-top: 0; }
          .grid-section .grid-pages .page-item-wrap .page-item:hover .content .other-content {
            opacity: 1; }
  @media (max-width: 767px) {
    .grid-section .grid-pages .column {
      width: 100%; }
    .grid-section .grid-pages .page-item-wrap.square:after {
      padding-bottom: 100%; }
    .grid-section .grid-pages .page-item-wrap.rect:after {
      padding-bottom: 100%; }
    .grid-section .grid-pages .page-item-wrap .page-item .content .page-title {
      font-size: 35px; }
    .grid-section .grid-pages .page-item-wrap .page-item .content .page-description {
      font-size: 16px; }
    .grid-section .section-title {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
      line-height: 1.2;
      display: flex;
      justify-content: center;
      align-items: center; } }

.home-services-bg .home-services {
  padding: 60px 0;
  max-width: 1024px;
  margin: auto;
  width: 100%;
  position: relative;
  z-index: 10; }
  .home-services-bg .home-services .home-services__title {
    margin: 0;
    padding-bottom: 40px;
    text-align: center; }
  .home-services-bg .home-services .services-wrap {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box; }
    .home-services-bg .home-services .services-wrap .service-box {
      margin-bottom: 20px;
      text-align: center;
      box-sizing: border-box; }
      .home-services-bg .home-services .services-wrap .service-box .service-title {
        border-bottom: 1px solid #2ab8a9;
        line-height: 38px;
        padding-bottom: 15px;
        margin-bottom: 20px;
        font-weight: 300;
        margin-top: 0; }
        .home-services-bg .home-services .services-wrap .service-box .service-title.no-border {
          border: none !important; }
      .home-services-bg .home-services .services-wrap .service-box p {
        text-align: center; }
      .home-services-bg .home-services .services-wrap .service-box .service-thumb {
        margin-bottom: 10px;
        min-height: 235px;
        padding-top: 20px;
        transition: all 0.4s ease;
        background-position: bottom center; }
        .home-services-bg .home-services .services-wrap .service-box .service-thumb:hover {
          min-height: 235px;
          padding-top: 0px;
          background: url(../img/shadow.png) no-repeat bottom center; }
        .home-services-bg .home-services .services-wrap .service-box .service-thumb img {
          border-radius: 100%;
          margin: auto;
          height: 180px !important;
          width: 180px !important;
          object-fit: cover; }
          .home-services-bg .home-services .services-wrap .service-box .service-thumb img.square {
            border-radius: 0px; }
          .home-services-bg .home-services .services-wrap .service-box .service-thumb img.rounded {
            border-radius: 25px; }
    .home-services-bg .home-services .services-wrap[data-col="3"] {
      justify-content: center; }
      .home-services-bg .home-services .services-wrap[data-col="3"] .service-box {
        width: calc(100% / 3);
        padding: 0 35px; }
    .home-services-bg .home-services .services-wrap[data-col="4"] .service-box {
      width: calc(100% / 4);
      padding: 0 35px; }
  @media (max-width: 991px) {
    .home-services-bg .home-services .services-wrap .service-box {
      width: calc(100% / 2) !important;
      padding: 0 35px !important; }
    .home-services-bg .home-services .service-thumb {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto; }
    .home-services-bg .home-services .service-content {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto; } }
  @media (max-width: 767px) {
    .home-services-bg .home-services .home-services h2 {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
      line-height: 1.2;
      display: flex;
      justify-content: center;
      align-items: center; } }
  @media (max-width: 565px) {
    .home-services-bg .home-services .services-wrap .service-box {
      width: 100% !important;
      padding: 0 35px !important; } }

.call-to-action {
  display: flex;
  padding: 15px 0; }
  .call-to-action .row {
    align-items: center;
    position: relative;
    z-index: 10; }
  .call-to-action .col-title {
    padding: 25px 15px; }
  .call-to-action .col-btn {
    display: flex;
    justify-content: flex-end;
    padding: 25px 15px; }
  @media (max-width: 991px) {
    .call-to-action {
      padding: 60px 0; }
      .call-to-action .col-title {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-bottom: 0; }
        .call-to-action .col-title .cta-title {
          max-width: 90%;
          margin-left: auto;
          margin-right: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
          margin-top: 0; }
      .call-to-action .col-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center; }
        .call-to-action .col-btn .content-btn {
          min-width: 140px; } }

.call-to-action-content {
  padding: 60px 0; }
  .call-to-action-content .container {
    position: relative;
    z-index: 10; }

.info-boxes-section {
  padding-top: 160px;
  padding-bottom: 130px; }
  .info-boxes-section .box-item__wrap {
    margin-bottom: 30px; }
  .info-boxes-section .box-item {
    padding: 10px; }
    .info-boxes-section .box-item .box-item__header {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center; }
    .info-boxes-section .box-item .box-item__title {
      text-align: center;
      margin: 0 auto;
      text-transform: none;
      color: #2a639d;
      display: block;
      max-width: 100%;
      white-space: normal;
      word-break: break-word; }
    .info-boxes-section .box-item .box-item__body {
      padding: 25px; }
      .info-boxes-section .box-item .box-item__body, .info-boxes-section .box-item .box-item__body p, .info-boxes-section .box-item .box-item__body ul li {
        text-align: center; }
      .info-boxes-section .box-item .box-item__body p {
        margin-top: 0; }
      .info-boxes-section .box-item .box-item__body ul, .info-boxes-section .box-item .box-item__body ol {
        margin-top: 0;
        padding-left: 0;
        margin-left: 0;
        list-style-position: inside; }
      .info-boxes-section .box-item .box-item__body h1, .info-boxes-section .box-item .box-item__body h2, .info-boxes-section .box-item .box-item__body h3, .info-boxes-section .box-item .box-item__body h4, .info-boxes-section .box-item .box-item__body h5, .info-boxes-section .box-item .box-item__body h6 {
        margin-top: 0;
        display: block; }
  .info-boxes-section .container {
    position: relative;
    z-index: 15; }
  @media (max-width: 991px) {
    .info-boxes-section {
      padding-top: 100px;
      padding-bottom: 100px; } }

.service-section-spaced {
  padding: 80px 0; }
  .service-section-spaced .content-wrap {
    position: relative;
    z-index: 15;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 1600px;
    max-width: calc(100% - 60px); }
    .service-section-spaced .content-wrap .service-section-spaced__title {
      background-color: transparent;
      display: block;
      text-align: center;
      margin-top: 0;
      margin-bottom: 40px; }
  .service-section-spaced .service-box-wrap {
    margin-bottom: 30px; }
  .service-section-spaced .service-box {
    width: 100%;
    position: relative;
    height: 270px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0;
    padding: 0; }
  .service-section-spaced .service-box-content {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px; }
    .service-section-spaced .service-box-content:after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      transition: 0.2s all ease;
      background-color: #000;
      opacity: 0.15;
      z-index: 10; }
    .service-section-spaced .service-box-content:hover:after {
      opacity: 0.4; }
  .service-section-spaced .service-title {
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
    position: relative;
    z-index: 15; }
  @media (max-width: 767px) {
    .service-section-spaced .content-wrap {
      max-width: calc(100% - 30px); } }

.posts-slideshow-section {
  padding-top: 65px;
  padding-bottom: 75px; }
  .posts-slideshow-section .container {
    position: relative;
    z-index: 15; }
  .posts-slideshow-section .block-title {
    text-align: center;
    display: block;
    margin: 0; }
  .posts-slideshow-section .posts-slider-wrapper {
    margin-top: 60px;
    max-width: 930px;
    margin-left: auto;
    margin-right: auto;
    position: relative; }
  .posts-slideshow-section .prev-arrow {
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateX(-85px) translateY(-50%);
    z-index: 10;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center; }
    .posts-slideshow-section .prev-arrow svg {
      height: 30px;
      width: auto;
      display: block; }
  .posts-slideshow-section .next-arrow {
    right: 0;
    position: absolute;
    top: 50%;
    transform: translateX(85px) translateY(-50%);
    z-index: 10;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center; }
    .posts-slideshow-section .next-arrow svg {
      height: 30px;
      width: auto;
      display: block;
      transform: rotate(180deg); }
  .posts-slideshow-section .slider-arrow svg path {
    fill: #1E73BE;
    transition: 0.3s all ease; }
  .posts-slideshow-section .slide-post {
    position: relative; }
    .posts-slideshow-section .slide-post .post-title {
      margin-top: 15px;
      margin-bottom: 10px;
      display: block; }
      .posts-slideshow-section .slide-post .post-title a {
        color: inherit; }
    .posts-slideshow-section .slide-post .post-thumbnail {
      display: block;
      position: relative; }
      .posts-slideshow-section .slide-post .post-thumbnail:after {
        display: block;
        content: "";
        padding-bottom: 56.65%; }
      .posts-slideshow-section .slide-post .post-thumbnail img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block; }
    .posts-slideshow-section .slide-post .post-excerpt, .posts-slideshow-section .slide-post .post-excerpt p {
      font-size: 16px;
      display: block;
      margin-top: 0; }
    .posts-slideshow-section .slide-post .btn-wrap {
      display: flex;
      align-items: center;
      margin-top: 15px; }
    .posts-slideshow-section .slide-post .post-meta {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 8px;
      margin-top: 15px; }
      .posts-slideshow-section .slide-post .post-meta, .posts-slideshow-section .slide-post .post-meta p {
        font-size: 15px; }
      .posts-slideshow-section .slide-post .post-meta__item {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        margin-right: 6px; }
      .posts-slideshow-section .slide-post .post-meta .divider {
        margin-left: 6px; }
  @media (max-width: 1100px) {
    .posts-slideshow-section .posts-slider-wrapper {
      margin-top: 40px; }
    .posts-slideshow-section .prev-arrow {
      transform: translateX(calc(-100% - 15px)) translateY(-50%); }
    .posts-slideshow-section .next-arrow {
      transform: translateX(calc(100% + 15px)) translateY(-50%); } }
  @media (max-width: 991px) {
    .posts-slideshow-section .posts-slider-wrapper {
      padding-left: 25px;
      padding-right: 25px; }
    .posts-slideshow-section .slider-arrow svg {
      height: 25px; }
    .posts-slideshow-section .prev-arrow {
      transform: translateX(calc(-50%)) translateY(-50%); }
    .posts-slideshow-section .next-arrow {
      transform: translateX(calc(50%)) translateY(-50%); } }

.image_info_section {
  padding-top: 90px;
  padding-bottom: 40px; }
  .image_info_section .image_info_section__title {
    margin-top: 0;
    text-align: center;
    display: block; }
  .image_info_section .container {
    position: relative;
    z-index: 15; }
  .image_info_section .service-info-row {
    margin-top: 40px; }
  .image_info_section .service-info-box-wrap {
    margin-bottom: 60px; }
    .image_info_section .service-info-box-wrap .service-info-box {
      width: 100%;
      max-width: 100%; }
      .image_info_section .service-info-box-wrap .service-info-box .service-info-box__title {
        text-align: center;
        display: block;
        margin: 22px 0; }
      .image_info_section .service-info-box-wrap .service-info-box .service-info-box__description {
        display: block;
        text-align: center; }
      .image_info_section .service-info-box-wrap .service-info-box .thumbnail-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative; }
        .image_info_section .service-info-box-wrap .service-info-box .thumbnail-wrap.circle img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 80%;
          height: 100%;
          border-radius: 100%;
          object-fit: cover;
          overflow: hidden;
          display: block;
          margin: auto; }
        .image_info_section .service-info-box-wrap .service-info-box .thumbnail-wrap.rect img {
          position: relative;
          width: 100%;
          height: 200px;
          object-fit: cover; }
        .image_info_section .service-info-box-wrap .service-info-box .thumbnail-wrap.circle:after {
          padding-bottom: 80%;
          display: block;
          content: ""; }
      .image_info_section .service-info-box-wrap .service-info-box .btn-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px; }

.flexible-boxes-section {
  padding-top: 60px;
  padding-bottom: 60px; }
  .flexible-boxes-section .container {
    position: relative;
    z-index: 10; }
  .flexible-boxes-section .section-title {
    display: block;
    text-align: center; }
  .flexible-boxes-section .flexible-boxes {
    margin-top: 60px; }
    .flexible-boxes-section .flexible-boxes .flexible-box {
      margin: 0;
      display: flex !important;
      width: 100% !important;
      justify-content: space-between !important;
      position: relative;
      vertical-align: top;
      padding: 0 15px;
      font-size: 19px;
      font-weight: 300;
      line-height: 1.4;
      margin-bottom: 20px; }
      .flexible-boxes-section .flexible-boxes .flexible-box:last-child {
        margin-bottom: 0; }
      .flexible-boxes-section .flexible-boxes .flexible-box.row-reverse {
        flex-direction: row-reverse; }
      .flexible-boxes-section .flexible-boxes .flexible-box .col-image {
        max-width: 40%;
        width: 100%;
        position: relative;
        height: 100%;
        padding: 15px;
        display: flex;
        flex-flow: column; }
      .flexible-boxes-section .flexible-boxes .flexible-box .col-content {
        max-width: 60%;
        display: flex;
        flex-flow: column;
        padding: 15px;
        position: relative;
        width: 100%;
        text-align: left !important; }
      .flexible-boxes-section .flexible-boxes .flexible-box .flexible-img {
        min-height: 230px;
        background-size: cover;
        background-position: center;
        margin-bottom: 15px;
        width: 100%;
        display: block;
        position: relative;
        height: 300px;
        object-fit: cover; }
      .flexible-boxes-section .flexible-boxes .flexible-box .flexible-box__title {
        margin-top: 0;
        margin-bottom: 20px; }
      .flexible-boxes-section .flexible-boxes .flexible-box .btn-wrap {
        display: flex;
        margin-top: 25px; }
  @media (max-width: 991px) {
    .flexible-boxes-section .flexible-boxes .flexible-box {
      margin-bottom: 60px;
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
      text-align: center; }
      .flexible-boxes-section .flexible-boxes .flexible-box:last-child {
        margin-bottom: 0; }
      .flexible-boxes-section .flexible-boxes .flexible-box .col-image {
        height: auto;
        max-height: unset;
        min-height: unset;
        width: 100%;
        position: relative;
        padding: 0;
        max-width: 420px;
        margin-left: auto;
        margin-right: auto; }
        .flexible-boxes-section .flexible-boxes .flexible-box .col-image:after {
          padding-bottom: 80%;
          display: block;
          content: ""; }
        .flexible-boxes-section .flexible-boxes .flexible-box .col-image .flexible-img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          min-height: 100%;
          max-height: 100%;
          object-fit: cover;
          background-size: cover; }
      .flexible-boxes-section .flexible-boxes .flexible-box .col-content {
        width: 100%;
        max-width: 420px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 25px; }
        .flexible-boxes-section .flexible-boxes .flexible-box .col-content .flexible-box__title {
          text-align: center; }
        .flexible-boxes-section .flexible-boxes .flexible-box .col-content .flexible-box__content {
          text-align: center; }
          .flexible-boxes-section .flexible-boxes .flexible-box .col-content .flexible-box__content, .flexible-boxes-section .flexible-boxes .flexible-box .col-content .flexible-box__content p {
            font-size: 17px; }
        .flexible-boxes-section .flexible-boxes .flexible-box .col-content .btn-wrap {
          display: flex;
          justify-content: center;
          align-items: center; } }

.map-section {
  padding-top: 70px;
  padding-bottom: 110px;
  background-color: #fff; }
  .map-section.map-section-wide {
    padding-bottom: 0 !important;
    padding-top: 0 !important; }
  .map-section .section-title {
    text-align: center;
    margin: 0;
    padding-bottom: 40px;
    padding-top: 40px;
    padding-left: 15px;
    padding-right: 15px; }
  .map-section .maps-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -10px;
    margin-right: -10px; }
    .map-section .maps-wrap .map {
      flex-grow: 1;
      flex-basis: calc(100% / 3 - 20px);
      position: relative;
      margin-left: 10px;
      margin-right: 10px;
      max-height: 460px;
      overflow: hidden; }
      .map-section .maps-wrap .map:after {
        display: block;
        content: "";
        padding-bottom: 60%;
        max-height: 600px; }
      .map-section .maps-wrap .map iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block; }
      .map-section .maps-wrap .map iframe.greyscale {
        filter: grayscale(100%); }
      .map-section .maps-wrap .map iframe.sepia {
        filter: sepia(100%); }
      .map-section .maps-wrap .map iframe.colorful {
        filter: hue-rotate(75deg); }
      .map-section .maps-wrap .map iframe.nighttime {
        filter: invert(90%); }
      .map-section .maps-wrap .map .google-map-wrap {
        width: 100%;
        height: 100%; }
        .map-section .maps-wrap .map .google-map-wrap .google-map {
          width: 100%;
          height: 100%; }
  @media (max-width: 1199px) {
    .map-section .maps-wrap {
      margin-left: auto;
      margin-right: auto; }
      .map-section .maps-wrap .map {
        min-width: auto;
        width: 100%;
        margin-bottom: 0px;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        margin-left: auto;
        margin-right: auto; } }
  @media (max-width: 767px) {
    .map-section .section-title {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
      line-height: 1.2;
      display: flex;
      justify-content: center;
      align-items: center; } }

.page-template-home-page-php .map-section.no-gap {
  padding-top: 0 !important; }

.page-template-home-page-php .map-section.no-gap .maps-wrap .map {
  margin-top: 0; }

.top-bar {
  width: 100%;
  display: none;
  z-index: 1000;
  min-height: 56px !important;
  max-height: 56px !important;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  overflow: hidden; }
  .top-bar[data-fixed="1"] {
    z-index: 1002; }
  @media (min-width: 1025px) {
    .top-bar[data-desktop="1"] {
      display: block; } }
  @media (max-width: 1024px) {
    .top-bar[data-mobile="1"] {
      display: block; } }
  .top-bar .top-bar-wrap {
    display: flex;
    width: 100% !important;
    justify-content: space-between;
    max-width: 1200px !important;
    margin: auto !important;
    position: relative;
    align-items: center;
    height: 56px !important; }
  .top-bar .top-bar-left {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    padding-left: 15px; }
    .top-bar .top-bar-left .widget-area {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-start; }
      .top-bar .top-bar-left .widget-area .widget {
        margin-left: 20px; }
        .top-bar .top-bar-left .widget-area .widget:first-child {
          margin-left: 0; }
    .top-bar .top-bar-left .simple-social-icons ul li:first-child {
      margin-left: 0 !important; }
  .top-bar .top-bar-right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0; }
    .top-bar .top-bar-right .widget-area {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end; }
      .top-bar .top-bar-right .widget-area .widget {
        margin-left: 20px; }
        .top-bar .top-bar-right .widget-area .widget:first-child {
          margin-left: 0; }
    .top-bar .top-bar-right .simple-social-icons ul li:last-child {
      margin-right: 0 !important; }
  .top-bar .top-bar-mobile {
    display: none; }
  .top-bar .simple-social-icons ul {
    margin: 0 !important; }
    .top-bar .simple-social-icons ul li {
      margin-bottom: 0 !important;
      margin-top: 0 !important; }
  @media (max-width: 1024px) {
    .top-bar .top-bar-left, .top-bar .top-bar-right {
      display: none !important; }
    .top-bar .top-bar-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%; }
      .top-bar .top-bar-mobile .widget-area {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center; } }

.top-bar[data-fixed="1"] {
  position: fixed; }

.sub_menu_carrets_off .genesis-nav-menu .menu-item-has-children > a:after {
  display: none !important; }

.desktop-navigation[data-style="a"] {
  z-index: 1001;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .3s ease;
  border-bottom: 3px solid transparent; }
  .desktop-navigation[data-style="a"] .wrap {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .desktop-navigation[data-style="a"] .logo {
    max-height: 80%;
    display: flex;
    align-items: center; }
    .desktop-navigation[data-style="a"] .logo * {
      max-height: 100%; }
    .desktop-navigation[data-style="a"] .logo img {
      max-height: 80px;
      max-width: 320px;
      transform: translateX(-10px); }
  .desktop-navigation[data-style="a"] .navbar {
    display: flex;
    align-items: center; }
    .desktop-navigation[data-style="a"] .navbar ul {
      margin: 0;
      padding: 0; }
      .desktop-navigation[data-style="a"] .navbar ul li {
        margin: 0;
        padding: 0 15px;
        display: inline; }
        .desktop-navigation[data-style="a"] .navbar ul li a {
          text-decoration: none; }
    .desktop-navigation[data-style="a"] .navbar .genesis-nav-menu {
      display: flex;
      align-items: center;
      clear: both;
      width: auto;
      text-align: center;
      margin: 0; }
      .desktop-navigation[data-style="a"] .navbar .genesis-nav-menu .menu-item-has-children > a {
        position: relative !important; }
        .desktop-navigation[data-style="a"] .navbar .genesis-nav-menu .menu-item-has-children > a:after {
          padding-left: 8px;
          content: "\f0d7";
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
      .desktop-navigation[data-style="a"] .navbar .genesis-nav-menu li {
        transition: 0.3s all ease; }
      .desktop-navigation[data-style="a"] .navbar .genesis-nav-menu a {
        display: block;
        transition: 0.3s all ease; }
      .desktop-navigation[data-style="a"] .navbar .genesis-nav-menu .sub-menu {
        left: -9999px;
        opacity: 0;
        position: absolute;
        transition: opacity .4s ease-in-out;
        width: 200px;
        z-index: 99;
        padding: 0;
        padding-top: 5px;
        border: none; }
        .desktop-navigation[data-style="a"] .navbar .genesis-nav-menu .sub-menu a {
          border-top: none;
          padding: 20px;
          position: relative;
          width: 200px; }
        .desktop-navigation[data-style="a"] .navbar .genesis-nav-menu .sub-menu li {
          padding: 0;
          margin: 0; }
          .desktop-navigation[data-style="a"] .navbar .genesis-nav-menu .sub-menu li > a {
            border: none;
            text-shadow: none;
            padding: 8px 10px !important; }
            .desktop-navigation[data-style="a"] .navbar .genesis-nav-menu .sub-menu li > a:after {
              display: none; }
        .desktop-navigation[data-style="a"] .navbar .genesis-nav-menu .sub-menu .sub-menu {
          margin: -55px 0 0 199px; }
      .desktop-navigation[data-style="a"] .navbar .genesis-nav-menu .menu-item {
        display: inline-block;
        text-align: left; }
        .desktop-navigation[data-style="a"] .navbar .genesis-nav-menu .menu-item:hover {
          position: static; }
          .desktop-navigation[data-style="a"] .navbar .genesis-nav-menu .menu-item:hover > .sub-menu {
            left: auto;
            opacity: 1; }
      .desktop-navigation[data-style="a"] .navbar .genesis-nav-menu > .first > a {
        padding-left: 0; }
      .desktop-navigation[data-style="a"] .navbar .genesis-nav-menu > .last > a {
        padding-right: 0; }
      .desktop-navigation[data-style="a"] .navbar .genesis-nav-menu > .right {
        float: right;
        list-style-type: none;
        padding: 30px 0; }
      .desktop-navigation[data-style="a"] .navbar .genesis-nav-menu > .right > a {
        display: inline;
        padding: 0; }
      .desktop-navigation[data-style="a"] .navbar .genesis-nav-menu > .rss > a {
        margin-left: 48px; }
      .desktop-navigation[data-style="a"] .navbar .genesis-nav-menu > .search {
        padding: 10px 0 0; }
  .desktop-navigation[data-style="a"] .contact-nav-btn {
    margin-left: 20px; }
  .desktop-navigation[data-style="a"] .mobile-nav-toggle-btn {
    display: none; }
  @media (max-width: 1199px) {
    .desktop-navigation[data-style="a"] .logo img {
      height: 65px; } }
  @media (max-width: 1024px) {
    .desktop-navigation[data-style="a"] .navbar {
      display: none; }
    .desktop-navigation[data-style="a"] .mobile-nav-toggle-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s all ease; }
      .desktop-navigation[data-style="a"] .mobile-nav-toggle-btn .mobile-nav-toggle-bar {
        transition: 0.3s all ease; } }

.desktop-navigation[data-style="b"], .desktop-navigation[data-style="c"] {
  border: 0 !important;
  position: absolute;
  z-index: 900;
  left: 0;
  right: 0;
  width: 100%;
  height: 50px;
  transition: 0.3s background-color ease, 0.3s backgroud, 0.3s border-bottom-color, 0.3s border-bottom-width; }
  .desktop-navigation[data-style="b"] .navbar, .desktop-navigation[data-style="c"] .navbar {
    height: 100%; }
  .desktop-navigation[data-style="b"] .mobile-navbar, .desktop-navigation[data-style="c"] .mobile-navbar {
    display: none; }
  .desktop-navigation[data-style="b"] .nav-primary, .desktop-navigation[data-style="c"] .nav-primary {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .desktop-navigation[data-style="b"] .nav-primary .menu, .desktop-navigation[data-style="c"] .nav-primary .menu {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
      .desktop-navigation[data-style="b"] .nav-primary .menu > li, .desktop-navigation[data-style="c"] .nav-primary .menu > li {
        height: 100%;
        display: block;
        position: relative;
        flex-grow: 1; }
        .desktop-navigation[data-style="b"] .nav-primary .menu > li > a, .desktop-navigation[data-style="c"] .nav-primary .menu > li > a {
          letter-spacing: 2px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 22px;
          position: relative;
          text-align: center; }
        .desktop-navigation[data-style="b"] .nav-primary .menu > li.menu-item-has-children > a:after, .desktop-navigation[data-style="c"] .nav-primary .menu > li.menu-item-has-children > a:after {
          padding-left: 8px;
          content: "\f0d7";
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
        .desktop-navigation[data-style="b"] .nav-primary .menu > li.menu-item-has-children:hover > .sub-menu, .desktop-navigation[data-style="c"] .nav-primary .menu > li.menu-item-has-children:hover > .sub-menu {
          opacity: 1;
          pointer-events: all; }
        .desktop-navigation[data-style="b"] .nav-primary .menu > li .sub-menu, .desktop-navigation[data-style="c"] .nav-primary .menu > li .sub-menu {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          width: 100%;
          pointer-events: none;
          opacity: 0;
          transition: opacity .4s ease-in-out; }
          .desktop-navigation[data-style="b"] .nav-primary .menu > li .sub-menu li a, .desktop-navigation[data-style="c"] .nav-primary .menu > li .sub-menu li a {
            display: block;
            padding: 8px 10px !important;
            text-align: center;
            transition: 0.3s all ease; }
  @media (max-width: 1024px) {
    .desktop-navigation[data-style="b"], .desktop-navigation[data-style="c"] {
      height: 100px; }
      .desktop-navigation[data-style="b"] .navbar, .desktop-navigation[data-style="c"] .navbar {
        display: none; }
      .desktop-navigation[data-style="b"] .mobile-navbar, .desktop-navigation[data-style="c"] .mobile-navbar {
        background-color: #f3f3f3;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px; }
        .desktop-navigation[data-style="b"] .mobile-navbar .mobile-nav-toggle-btn .mobile-nav-toggle-bar, .desktop-navigation[data-style="c"] .mobile-navbar .mobile-nav-toggle-btn .mobile-nav-toggle-bar {
          transition: 0.3s all ease; }
        .desktop-navigation[data-style="b"] .mobile-navbar .logo img, .desktop-navigation[data-style="c"] .mobile-navbar .logo img {
          height: 65px;
          max-height: 80px;
          max-width: 320px;
          transform: translateX(-10px); } }

.nav-header[data-style="b"] {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #fefbfa;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  height: 254px; }
  .nav-header[data-style="b"] .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .nav-header[data-style="b"] .wrap .widget {
      margin-bottom: 20px; }
      .nav-header[data-style="b"] .wrap .widget a {
        transition: 0.3s all ease; }
      .nav-header[data-style="b"] .wrap .widget:last-child {
        margin-bottom: 0; }
    .nav-header[data-style="b"] .wrap img {
      max-height: 100px;
      display: block;
      object-fit: contain; }
  @media (max-width: 1024px) {
    .nav-header[data-style="b"] {
      display: none; } }

.nav-header[data-style="c"] {
  position: absolute;
  z-index: 100;
  left: 0;
  right: 0;
  height: 140px;
  background-color: #fefbfa;
  padding: 20px 0; }
  .nav-header[data-style="c"]__row {
    justify-content: space-between;
    align-items: center; }
  .nav-header[data-style="c"] .header-left {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .nav-header[data-style="c"] .header-right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right; }
  .nav-header[data-style="c"] .widget {
    font-size: 16px; }
    .nav-header[data-style="c"] .widget p {
      font-size: 16px; }
    .nav-header[data-style="c"] .widget img {
      max-height: 85px;
      object-fit: contain;
      display: block;
      width: auto; }
    .nav-header[data-style="c"] .widget .btn {
      margin-bottom: 12px;
      font-size: 12px !important;
      padding: 10px 12px;
      margin-right: 6px; }
  @media (max-width: 1024px) {
    .nav-header[data-style="c"] {
      display: none; } }

.desktop-navigation[data-style="e"] {
  padding-top: 3px;
  z-index: 900;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s background-color ease, 0.3s backgroud, 0.3s border-bottom-color, 0.3s border-bottom-width;
  border-bottom: 3px solid transparent; }
  .desktop-navigation[data-style="e"] .wrap {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .desktop-navigation[data-style="e"] .logo {
    max-height: 80%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .desktop-navigation[data-style="e"] .logo img {
      display: block;
      object-fit: contain;
      max-height: 80px;
      max-width: 250px; }
  .desktop-navigation[data-style="e"] .contact-nav-btn {
    margin-left: 20px; }
  .desktop-navigation[data-style="e"] .navbar {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center; }
    .desktop-navigation[data-style="e"] .navbar__row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .desktop-navigation[data-style="e"] .navbar .nav-left {
      width: 35%;
      display: flex;
      align-items: center;
      justify-content: flex-start; }
    .desktop-navigation[data-style="e"] .navbar .logo {
      width: calc(30%); }
    .desktop-navigation[data-style="e"] .navbar .nav-right {
      width: 35%;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
    .desktop-navigation[data-style="e"] .navbar ul {
      margin: 0;
      padding: 0; }
      .desktop-navigation[data-style="e"] .navbar ul li {
        margin: 0;
        padding: 0 15px;
        display: inline; }
        .desktop-navigation[data-style="e"] .navbar ul li a {
          text-decoration: none; }
    .desktop-navigation[data-style="e"] .navbar .genesis-nav-menu {
      display: flex;
      align-items: center;
      clear: both;
      width: auto;
      text-align: center;
      margin: 0; }
      .desktop-navigation[data-style="e"] .navbar .genesis-nav-menu li {
        transition: 0.3s all ease; }
      .desktop-navigation[data-style="e"] .navbar .genesis-nav-menu a {
        display: block;
        transition: 0.3s all ease; }
      .desktop-navigation[data-style="e"] .navbar .genesis-nav-menu .sub-menu {
        left: -9999px;
        opacity: 0;
        position: absolute;
        transition: opacity .4s ease-in-out;
        width: 200px;
        z-index: 99;
        padding: 0;
        padding-top: 5px;
        border: none; }
        .desktop-navigation[data-style="e"] .navbar .genesis-nav-menu .sub-menu a {
          border-top: none;
          padding: 20px;
          position: relative;
          width: 200px; }
        .desktop-navigation[data-style="e"] .navbar .genesis-nav-menu .sub-menu li {
          padding: 0;
          margin: 0; }
          .desktop-navigation[data-style="e"] .navbar .genesis-nav-menu .sub-menu li > a {
            border: none;
            text-shadow: none;
            padding: 8px 10px !important; }
            .desktop-navigation[data-style="e"] .navbar .genesis-nav-menu .sub-menu li > a:after {
              display: none; }
        .desktop-navigation[data-style="e"] .navbar .genesis-nav-menu .sub-menu .sub-menu {
          margin: -55px 0 0 199px; }
      .desktop-navigation[data-style="e"] .navbar .genesis-nav-menu .menu-item {
        display: inline-block;
        text-align: left; }
        .desktop-navigation[data-style="e"] .navbar .genesis-nav-menu .menu-item.menu-item-has-children > a:after {
          padding-left: 8px;
          content: "\f0d7";
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
        .desktop-navigation[data-style="e"] .navbar .genesis-nav-menu .menu-item:hover {
          position: static; }
          .desktop-navigation[data-style="e"] .navbar .genesis-nav-menu .menu-item:hover > .sub-menu {
            left: auto;
            opacity: 1; }
      .desktop-navigation[data-style="e"] .navbar .genesis-nav-menu > .first > a {
        padding-left: 0; }
      .desktop-navigation[data-style="e"] .navbar .genesis-nav-menu > .last > a {
        padding-right: 0; }
      .desktop-navigation[data-style="e"] .navbar .genesis-nav-menu > .right {
        float: right;
        list-style-type: none;
        padding: 30px 0; }
      .desktop-navigation[data-style="e"] .navbar .genesis-nav-menu > .right > a {
        display: inline;
        padding: 0; }
      .desktop-navigation[data-style="e"] .navbar .genesis-nav-menu > .rss > a {
        margin-left: 48px; }
      .desktop-navigation[data-style="e"] .navbar .genesis-nav-menu > .search {
        padding: 10px 0 0; }
  .desktop-navigation[data-style="e"] .mobile-navbar {
    display: none; }
  @media (max-width: 1024px) {
    .desktop-navigation[data-style="e"] .navbar {
      display: none; }
    .desktop-navigation[data-style="e"] .mobile-navbar {
      width: 100%;
      background-color: #f3f3f3;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px; }
      .desktop-navigation[data-style="e"] .mobile-navbar .mobile-nav-toggle-btn i {
        color: #bb8844; }
      .desktop-navigation[data-style="e"] .mobile-navbar .logo img {
        height: 65px;
        max-height: 80px;
        max-width: 320px;
        transform: translateX(-10px); } }

.desktop-navigation[data-style="d"] {
  z-index: 900;
  left: 0;
  right: 0;
  width: 100%;
  height: 82px;
  transition: 0.3s background-color ease, 0.3s backgroud, 0.3s border-bottom-color, 0.3s border-bottom-width;
  border-top: 3px solid #2a639d;
  border-bottom: 3px solid #2a639d; }
  .desktop-navigation[data-style="d"] .navbar {
    height: 100%; }
  .desktop-navigation[data-style="d"] .mobile-navbar {
    display: none; }
  .desktop-navigation[data-style="d"] .nav-primary {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .desktop-navigation[data-style="d"] .nav-primary .menu {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
      .desktop-navigation[data-style="d"] .nav-primary .menu > li {
        height: 100%;
        display: block;
        position: relative;
        z-index: 100; }
        .desktop-navigation[data-style="d"] .nav-primary .menu > li > a {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 1px;
          text-transform: capitalize;
          padding: 0 30px;
          transition: 0.3s all ease; }
        .desktop-navigation[data-style="d"] .nav-primary .menu > li.menu-item-has-children > a:after {
          padding-left: 8px;
          content: "\f0d7";
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
        .desktop-navigation[data-style="d"] .nav-primary .menu > li.menu-item-has-children:hover > .sub-menu {
          opacity: 1;
          pointer-events: all; }
        .desktop-navigation[data-style="d"] .nav-primary .menu > li .sub-menu {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          width: 100%;
          pointer-events: none;
          opacity: 0;
          transition: opacity .4s ease-in-out; }
          .desktop-navigation[data-style="d"] .nav-primary .menu > li .sub-menu:before {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            border-bottom-color: #000;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-width: 11px;
            margin-left: -11px; }
          .desktop-navigation[data-style="d"] .nav-primary .menu > li .sub-menu li a {
            transition: 0.3s all ease;
            padding: 16px 20px;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0px;
            text-align: left;
            text-transform: none;
            border-bottom: 1px solid #fff;
            display: block;
            line-height: 1.35; }
  @media (max-width: 1024px) {
    .desktop-navigation[data-style="d"] {
      height: 100px; }
      .desktop-navigation[data-style="d"] .navbar {
        display: none; }
      .desktop-navigation[data-style="d"] .mobile-navbar {
        background-color: #f3f3f3;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px; }
        .desktop-navigation[data-style="d"] .mobile-navbar .mobile-nav-toggle-btn .mobile-nav-toggle-bar {
          transition: 0.3s all ease; } }

/************************
         Header Inner
*************************/
.header-inner {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column; }
  .header-inner[data-image-global="false"] {
    display: none; }

.header-inner.in_header[data-size="tall"] {
  height: 550px; }
  @media (max-width: 991px) {
    .header-inner.in_header[data-size="tall"] {
      height: 350px; } }

.header-inner.in_header[data-size="standard"] {
  height: 350px; }

.header-inner.in_header[data-size="little"] {
  height: 150px; }

.header-inner.in_header .page-title {
  display: none; }

.header-inner.in_header .header-inner__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 5; }

.header-inner.in_content {
  width: 1000px !important;
  max-width: calc(100% - 30px);
  margin-left: auto;
  margin-right: auto;
  padding-top: 0 !important;
  margin-top: 65px; }
  .header-inner.in_content[data-size="tall"] .attachment-post-thumbnail {
    height: 550px; }
    @media (max-width: 991px) {
      .header-inner.in_content[data-size="tall"] .attachment-post-thumbnail {
        height: 350px; } }
  .header-inner.in_content[data-size="standard"] .attachment-post-thumbnail {
    height: 350px; }
  .header-inner.in_content[data-size="little"] .attachment-post-thumbnail {
    height: 150px; }
  .header-inner.in_content[data-image="false"] {
    height: auto !important; }
    .header-inner.in_content[data-image="false"] + .page-template .internal-page-template {
      padding-top: 5px !important; }
  .header-inner.in_content .header-inner__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 5; }
  .header-inner.in_content .page-title {
    padding-bottom: 10px; }
  .header-inner.in_content .page-overlay, .header-inner.in_content .blog-page-overlay {
    display: none; }
  .header-inner.in_content.ho-banner-image {
    padding-top: 155px; }

.header-inner.in_header_title {
  display: flex;
  align-items: center; }
  .header-inner.in_header_title[data-size="tall"] {
    height: 550px; }
    @media (max-width: 991px) {
      .header-inner.in_header_title[data-size="tall"] {
        height: 350px; } }
  .header-inner.in_header_title[data-size="standard"] {
    height: 350px; }
  .header-inner.in_header_title[data-size="little"] {
    height: 150px; }
  .header-inner.in_header_title .container {
    height: 100%;
    display: flex;
    align-items: center; }
  .header-inner.in_header_title .page-title {
    width: 100%;
    display: block;
    position: relative;
    z-index: 15;
    color: #fff;
    line-height: 1; }
  .header-inner.in_header_title .header-inner__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 5; }

.page-overlay, .blog-page-overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
  width: 100%;
  height: 100%; }

.nav_style_d .header-inner {
  position: relative; }
  .nav_style_d .header-inner[data-size="little"] .d_header_logo {
    display: none; }

.d_header_logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%); }
  .d_header_logo .img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 85%;
    max-width: 450px !important;
    max-height: 250px !important; }
  .d_header_logo img {
    padding: 15px;
    position: relative;
    z-index: 15;
    display: block;
    object-fit: contain;
    max-width: 100%;
    width: 100%;
    height: 100%;
    max-height: 220px; }

.pre-footer-area {
  padding: 35px; }
  .pre-footer-area ul, .pre-footer-area ol {
    list-style-type: none; }
  .pre-footer-area li {
    list-style-type: none; }

.footer .simple-social-icons ul {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto !important;
  margin-right: auto !important; }

.footer-playful {
  padding-top: 55px;
  padding-bottom: 30px; }
  .footer-playful .footer-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #fff;
    padding-top: 50px;
    box-sizing: border-box; }
  .footer-playful .footer-col {
    box-sizing: border-box;
    width: calc(100% / 3);
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    text-align: center; }
    .footer-playful .footer-col:after {
      display: block;
      content: "";
      height: calc(100% - 50px);
      width: 1px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: #252627; }
    .footer-playful .footer-col:last-child:after {
      display: none; }
    .footer-playful .footer-col .widget {
      margin-bottom: 50px;
      text-align: center; }
      .footer-playful .footer-col .widget .widget-title {
        display: block;
        text-align: center;
        margin: 0;
        margin-bottom: 25px !important; }
      .footer-playful .footer-col .widget h1,
      .footer-playful .footer-col .widget h2,
      .footer-playful .footer-col .widget h3,
      .footer-playful .footer-col .widget h4,
      .footer-playful .footer-col .widget h5,
      .footer-playful .footer-col .widget h6 {
        margin: 0;
        display: block;
        margin-bottom: 20px; }
      .footer-playful .footer-col .widget .btn {
        white-space: nowrap;
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;
        display: inline-block;
        margin-top: 10px; }
      .footer-playful .footer-col .widget p {
        margin-top: 0;
        margin-bottom: .6em;
        line-height: 1.35; }
        .footer-playful .footer-col .widget p:last-child {
          margin-bottom: 0; }
  .footer-playful .copyright-area .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    margin-top: 10px; }
  .footer-playful .copyright-area .col-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px; }
  .footer-playful .copyright-area .col-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 15px; }
  @media (max-width: 991px) {
    .footer-playful .footer-row {
      padding-top: 0; }
    .footer-playful .footer-col {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px; }
      .footer-playful .footer-col .widget {
        margin: 45px 0; }
      .footer-playful .footer-col:after {
        display: block;
        bottom: 0;
        width: 100%;
        right: 0;
        left: 0;
        top: auto;
        height: 1px; } }
  @media (max-width: 660px) {
    .footer-playful .footer-col {
      padding-left: 15px;
      padding-right: 15px; } }
  @media (max-width: 400px) {
    .footer-playful .copyright-area .row {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center; } }

/****************

 Footer A

****************/
.footer-a .wrap {
  padding-top: 70px;
  padding-bottom: 20px; }

.footer-a .row {
  justify-content: space-between; }

.footer-a .sidebar-col {
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: calc(100% / 3);
  padding: 0 15px;
  margin-bottom: 35px; }
  .footer-a .sidebar-col .widget {
    text-align: center;
    margin-bottom: 20px; }
    .footer-a .sidebar-col .widget .widget-title {
      text-align: center;
      margin: 0;
      margin-bottom: 20px; }
    .footer-a .sidebar-col .widget .textwidget p {
      margin-top: 0; }

.footer-a .copyright-bar {
  min-height: 75px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .footer-a .copyright-bar .copyright-bar-col {
    padding: 25px 15px; }
    .footer-a .copyright-bar .copyright-bar-col .widget p {
      margin: 0;
      font-size: 17px; }

@media (max-width: 991px) {
  .footer-a .row {
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .footer-a .sidebar-col {
    width: 100%;
    flex-basis: 100%;
    text-align: center;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto; }
  .footer-a .copyright-bar {
    padding: 25px 0; }
    .footer-a .copyright-bar .copyright-bar-col {
      padding: 0 15px;
      margin-bottom: 15px; }
      .footer-a .copyright-bar .copyright-bar-col:last-child {
        margin-bottom: 0; } }

/****************

Custom Footer

****************/
.custom-footer[data-cols="4"] .sidebar-col {
  flex-basis: calc(100% / 4); }

.custom-footer[data-cols="3"] .sidebar-col {
  flex-basis: calc(100% / 3); }

.custom-footer[data-cols="2"] .sidebar-col {
  flex-basis: calc(100% / 2); }

.custom-footer[data-cols="1"] .sidebar-col {
  flex-basis: calc(100% / 1); }

/****************

 Footer B

****************/
.footer-denver {
  padding: 35px 0; }
  .footer-denver .widget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    list-style-type: none;
    margin-bottom: 20px; }
    .footer-denver .widget .btn-wrap, .footer-denver .widget .footer-btn-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap; }
      .footer-denver .widget .btn-wrap .btn, .footer-denver .widget .footer-btn-wrap .btn {
        margin: 15px 8px; }
    .footer-denver .widget:last-child {
      margin-bottom: 0; }
    .footer-denver .widget, .footer-denver .widget p {
      font-size: 16px;
      color: #fff; }
      .footer-denver .widget a, .footer-denver .widget p a {
        color: #fff;
        transition: 0.3s all ease; }
        .footer-denver .widget a:hover, .footer-denver .widget p a:hover {
          color: #bb8844; }

.mobile-navigation, .mobile-navigation__overlay {
  display: none; }

@media (max-width: 1024px) {
  .mobile-navigation__overlay {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: fixed;
    width: 55%;
    min-height: 100vh;
    max-height: 100vh;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100000;
    overflow: hidden;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    -webkit-transform: translateX(101%);
    -ms-transform: translateX(101%);
    transform: translateX(101%);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .mobile-navigation__overlay img {
    display: block;
    -o-object-fit: contain;
    object-fit: contain;
    max-width: 70%;
    height: auto;
    margin: auto; }
  .mobile-navigation__overlay.active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  .mobile-navigation {
    -webkit-transform: translateX(-101%);
    -ms-transform: translateX(-101%);
    transform: translateX(-101%);
    display: block;
    position: fixed;
    width: 45%;
    min-height: 100vh;
    max-height: 100vh;
    background: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100000;
    overflow: hidden;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease; }
  .mobile-navigation__scroller {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
  .mobile-navigation.active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  .mobile-navigation__template {
    position: relative;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: block;
    overflow: hidden; }
  .mobile-navigation__template .container {
    width: 100%;
    max-width: calc(100% - 100px);
    margin-left: auto;
    margin-right: auto; }
  .mobile-navigation__header {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    height: 100px; }
  .mobile-navigation__header .container {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .mobile-navigation__header .close-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 20px; }
  .mobile-navigation__header .close-button svg {
    height: 18px;
    width: auto;
    display: block;
    -o-object-fit: contain;
    object-fit: contain; }
  .mobile-navigation__header .close-label {
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    font-size: 17px;
    font-weight: 400; }
  .mobile-navigation__header .close-button polygon {
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease; }
  /* Menu */
  .mobile-navigation__menu {
    padding: 0; }
  .mobile-navigation__menu .menu {
    margin: 0 !important;
    padding: 25px 0;
    display: block;
    width: 100%;
    list-style-type: none; }
  .mobile-navigation__menu .menu ul {
    list-style-type: none;
    list-style: none; }
  .mobile-navigation__menu .menu > li {
    margin: 25px 0;
    line-height: 1.6;
    font-size: 18px;
    min-height: 20px;
    list-style-type: none;
    list-style: none; }
  .mobile-navigation__menu .menu li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease; }
  .mobile-navigation__menu .menu li a .sub-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    padding: 5px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px; }
  .mobile-navigation__menu .menu li a .sub-toggle i {
    font-size: 90%;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease; }
  .mobile-navigation__menu .menu li a .sub-toggle.active i {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }
  .mobile-navigation__menu .menu li .sub-menu {
    display: none;
    padding-left: 25px;
    padding-top: 15px;
    padding-bottom: 10px; }
  .mobile-navigation__menu .menu li .sub-menu li {
    margin: 10px 0; }
  .mobile-navigation__menu .menu li .sub-menu li a {
    font-size: 16px; }
  /* Footer */
  .mobile-navigation__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 20px;
    padding-bottom: 20px; }
  .mobile-navigation__footer .mobile-widget {
    padding: 12px 0;
    list-style-type: none; }
  .mobile-navigation__footer ul {
    list-style-type: none;
    padding: 0;
    list-style: none; }
  .mobile-navigation__footer ul li {
    list-style-type: none;
    list-style: none; }
  /* Other */
  .utility-bar {
    max-width: 100vw; }
  .mobile-navigation .menu-main-menu-container {
    display: block; }
  .utility-bar {
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-size: 15px; }
  .utility-bar .wrap-1 {
    height: 56px;
    position: relative; }
  .utility-bar .wrap-1 .utility-bar-left {
    height: 56px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  #mob-icon {
    display: block;
    position: absolute;
    right: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  .logo img {
    max-width: 220px; } }

@media (min-width: 661px) {
  .mobile-navigation__header .mobile-nav-image {
    display: none; } }

@media (max-width: 660px) {
  .mobile-navigation__menu .menu {
    padding-bottom: 40px; }
  .mobile-navigation {
    width: 100% !important; }
  .mobile-navigation__overlay {
    display: none !important; }
  .mobile-navigation__header.show-logo .mobile-nav-image {
    height: 100%;
    max-width: 65%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .mobile-navigation__header.show-logo .mobile-nav-image img {
    max-width: 100%;
    height: 95%;
    display: block;
    -o-object-fit: contain;
    object-fit: contain; }
  /* Hide Label */
  .mobile-navigation__header.show-logo .close-label {
    display: none; }
  /* Hide Logo */
  .mobile-navigation__header.show-label .mobile-nav-image {
    display: none; } }

@media (max-width: 350px) {
  .mobile-navigation__menu {
    padding: 15px 0; }
  .mobile-navigation__menu .menu > li {
    margin: 20px 0; }
  .mobile-navigation__template .container {
    max-width: calc(100% - 30px); }
  .mobile-navigation__header {
    height: 70px; }
  .mobile-navigation__footer {
    padding: 10px 0; }
  .mobile-navigation__menu .menu {
    padding: 0; } }

/* Mobile Menu Orientation Change */
@media (max-width: 991px) and (orientation: landscape) {
  .mobile-navigation__footer {
    height: 0 !important;
    display: none !important; }
  .mobile-navigation__header {
    height: 70px; }
  .mobile-navigation__menu .menu {
    padding: 15px 0; } }

.services-page-template .services-grid {
  margin-top: 40px;
  margin-bottom: 40px; }

.services-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px; }
  @media (min-width: 992px) {
    .services-grid[data-col='2'] .service-item {
      width: calc(100% / 2 - 40px); }
    .services-grid[data-col='3'] .service-item {
      width: calc(100% / 3 - 40px); }
    .services-grid[data-col='4'] .service-item {
      width: calc(100% / 4 - 40px); } }
  .services-grid .service-item {
    width: calc(100% / 3 - 40px);
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 40px; }
    .services-grid .service-item__photo {
      position: relative;
      display: block;
      width: 100%; }
      .services-grid .service-item__photo:after {
        display: block;
        content: "";
        padding-bottom: 100%; }
      .services-grid .service-item__photo img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
    .services-grid .service-item__title {
      margin-top: 25px;
      margin-bottom: 20px;
      display: block;
      text-align: center; }
    .services-grid .service-item__description {
      display: block;
      text-align: center; }
  @media (max-width: 991px) {
    .services-grid .service-item {
      width: calc(100% / 2 - 40px); } }
  @media (max-width: 660px) {
    .services-grid .service-item {
      width: calc(100% - 40px);
      max-width: 300px;
      margin-left: auto;
      margin-right: auto; } }

.blog-template {
  background: #fff;
  padding: 50px 0; }
  .blog-template .posts-list-wrap {
    margin-top: 35px; }
  .blog-template .pagination-wrap {
    margin-top: 35px;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 14px; }
    .blog-template .pagination-wrap .first-page {
      margin-right: 10px; }
    .blog-template .pagination-wrap .last-page {
      margin-left: 10px; }
    .blog-template .pagination-wrap .nav-links {
      display: flex;
      align-items: center; }
      .blog-template .pagination-wrap .nav-links span, .blog-template .pagination-wrap .nav-links .current {
        cursor: default; }
      .blog-template .pagination-wrap .nav-links .page-numbers {
        padding: 0 3px;
        font-size: 14px;
        min-width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 6px;
        transition: 0.3s all ease; }
        .blog-template .pagination-wrap .nav-links .page-numbers.prev, .blog-template .pagination-wrap .nav-links .page-numbers.next {
          padding-bottom: 2px; }
        .blog-template .pagination-wrap .nav-links .page-numbers:hover, .blog-template .pagination-wrap .nav-links .page-numbers.current {
          background-color: #2a639d;
          color: #fff; }
    .blog-template .pagination-wrap[data-shape=circle] .nav-links .page-numbers {
      border-radius: 100%; }
    @media (max-width: 565px) {
      .blog-template .pagination-wrap .first-page {
        margin-right: 6px; }
      .blog-template .pagination-wrap .last-page {
        margin-left: 6px; }
      .blog-template .pagination-wrap .nav-links {
        min-width: 26px;
        height: 26px; }
        .blog-template .pagination-wrap .nav-links .page-numbers {
          margin: 0 2px; } }
  .blog-template .posts-list {
    width: 100%; }
    .blog-template .posts-list .post-wrap {
      width: 100%;
      display: flex; }

.post-classic-left {
  margin-bottom: 55px;
  display: flex;
  width: 100%; }
  .post-classic-left .thumbnail-wrap {
    width: 320px;
    min-width: 320px;
    display: block;
    position: relative; }
    .post-classic-left .thumbnail-wrap:after {
      display: block;
      content: "";
      padding-bottom: 100%; }
    .post-classic-left .thumbnail-wrap img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .post-classic-left .post-info {
    padding-left: 35px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between; }
    .post-classic-left .post-info__meta {
      min-height: 60px; }
    .post-classic-left .post-info .post-meta {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      color: #767676;
      line-height: 1; }
      .post-classic-left .post-info .post-meta, .post-classic-left .post-info .post-meta p {
        font-size: 16px; }
      .post-classic-left .post-info .post-meta a {
        display: block;
        transition: 0.3s all ease; }
      .post-classic-left .post-info .post-meta .post-meta-item__author {
        margin-bottom: 10px; }
      .post-classic-left .post-info .post-meta .post-meta-item__cats {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px; }
        .post-classic-left .post-info .post-meta .post-meta-item__cats a {
          border-left: 1px solid #c2c2c2;
          padding-left: 10px;
          margin-left: 10px; }
      .post-classic-left .post-info .post-meta .post-meta-item__tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px; }
        .post-classic-left .post-info .post-meta .post-meta-item__tags a {
          border-left: 1px solid #c2c2c2;
          padding-left: 10px;
          margin-left: 10px; }
          .post-classic-left .post-info .post-meta .post-meta-item__tags a:last-child {
            border-right: 0;
            padding-right: 0;
            margin-right: 0; }
    .post-classic-left .post-info .post-date {
      margin-top: 5px;
      font-size: 14px;
      color: #959595;
      line-height: 1.714; }
    .post-classic-left .post-info .post-title {
      display: block;
      margin-bottom: 10px; }
    .post-classic-left .post-info .post-excerpt, .post-classic-left .post-info .post-excerpt p {
      font-size: 18px; }
    .post-classic-left .post-info .post-permalink {
      margin-top: 25px;
      min-width: 150px; }
  @media (max-width: 767px) {
    .post-classic-left {
      margin-bottom: 60px;
      flex-direction: column; }
      .post-classic-left .thumbnail-wrap {
        width: 100%; }
        .post-classic-left .thumbnail-wrap:after {
          padding-bottom: 56.25%; }
      .post-classic-left .post-info {
        padding-top: 25px;
        padding-left: 0;
        padding-right: 0; } }

.post-classic-top {
  width: 100%;
  margin-bottom: 60px; }
  .post-classic-top .thumbnail-wrap {
    width: 100%;
    display: block;
    position: relative;
    max-height: 300px;
    overflow: hidden; }
    .post-classic-top .thumbnail-wrap:after {
      display: block;
      content: "";
      padding-bottom: 56.25%; }
    .post-classic-top .thumbnail-wrap img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: auto; }
  .post-classic-top .post-info {
    padding-top: 25px; }
    .post-classic-top .post-info .post-meta {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      color: #767676;
      line-height: 1; }
      .post-classic-top .post-info .post-meta, .post-classic-top .post-info .post-meta p {
        font-size: 16px; }
      .post-classic-top .post-info .post-meta a {
        display: block;
        transition: 0.3s all ease; }
      .post-classic-top .post-info .post-meta .post-meta-item__author {
        margin-bottom: 10px; }
      .post-classic-top .post-info .post-meta .post-meta-item__cats {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px; }
        .post-classic-top .post-info .post-meta .post-meta-item__cats a {
          display: flex;
          padding-left: 10px;
          margin-left: 10px;
          border-left: 1px solid #c2c2c2; }
      .post-classic-top .post-info .post-meta .post-meta-item__tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px; }
        .post-classic-top .post-info .post-meta .post-meta-item__tags a {
          padding-left: 10px;
          margin-left: 10px;
          border-left: 1px solid #c2c2c2; }
          .post-classic-top .post-info .post-meta .post-meta-item__tags a:last-child {
            border-right: 0;
            padding-right: 0;
            margin-right: 0; }
    .post-classic-top .post-info .post-date {
      font-size: 14px;
      color: #959595;
      line-height: 1.714;
      margin-bottom: 25px;
      display: block; }
    .post-classic-top .post-info .post-title {
      display: block;
      margin-bottom: 20px; }
    .post-classic-top .post-info .post-excerpt, .post-classic-top .post-info .post-excerpt p {
      font-size: 14px;
      color: #767676;
      line-height: 1.714; }
    .post-classic-top .post-info .post-permalink {
      margin-top: 30px;
      min-width: 150px;
      display: inline-flex;
      align-items: center;
      justify-content: center; }

.post-gridded {
  width: 100%;
  background-color: #f4f4f4;
  margin-bottom: 30px; }
  .post-gridded .thumbnail-wrap {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center; }
    .post-gridded .thumbnail-wrap:after {
      padding-bottom: 100%;
      display: block;
      content: ""; }
    .post-gridded .thumbnail-wrap img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 10; }
    .post-gridded .thumbnail-wrap .thumbnail-label {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 15;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      color: #fff;
      text-align: center;
      transition: 0.3s all ease;
      background-color: rgba(0, 0, 0, 0.3);
      opacity: 0; }
    .post-gridded .thumbnail-wrap:hover .thumbnail-label {
      opacity: 1; }
  .post-gridded .post-info {
    display: block;
    width: 100%;
    padding: 28px;
    text-align: center; }
    .post-gridded .post-info .post-date {
      display: block;
      text-align: center;
      font-size: 14px;
      color: #686868;
      line-height: 0.203; }
    .post-gridded .post-info .post-title {
      text-align: center;
      margin: 20px 0;
      font-size: 18px;
      min-height: 65px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .post-gridded .post-info .post-title a {
        transition: 0.3s all ease; }
    .post-gridded .post-info .post-meta {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      color: #767676;
      line-height: 1; }
      .post-gridded .post-info .post-meta, .post-gridded .post-info .post-meta p {
        font-size: 14px; }
      .post-gridded .post-info .post-meta a {
        display: block;
        transition: 0.3s all ease; }
      .post-gridded .post-info .post-meta .post-meta-item__cats {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px; }
        .post-gridded .post-info .post-meta .post-meta-item__cats a {
          border-right: 1px solid #c2c2c2;
          padding-right: 10px;
          margin-right: 10px; }
      .post-gridded .post-info .post-meta .post-meta-item__tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px; }
        .post-gridded .post-info .post-meta .post-meta-item__tags a {
          border-right: 1px solid #c2c2c2;
          padding-right: 10px;
          margin-right: 10px; }
          .post-gridded .post-info .post-meta .post-meta-item__tags a:last-child {
            border-right: 0;
            padding-right: 0;
            margin-right: 0; }
  .post-gridded[data-col="4"] .post-info {
    padding: 25px 15px; }
    .post-gridded[data-col="4"] .post-info .post-title {
      min-height: 60px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center; }
    .post-gridded[data-col="4"] .post-info .post-meta, .post-gridded[data-col="4"] .post-info .post-meta p {
      font-size: 12px; }

.single-post-template .content-col .page-title {
  font-size: 40px; }

.single-post-template .content-col .post-meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #767676;
  line-height: 1; }
  .single-post-template .content-col .post-meta a {
    display: block;
    transition: 0.3s all ease; }
  .single-post-template .content-col .post-meta .post-meta-item__author {
    margin-bottom: 10px;
    display: flex; }
    .single-post-template .content-col .post-meta .post-meta-item__author a {
      margin-left: 4px; }
  .single-post-template .content-col .post-meta .post-meta-item__cats {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px; }
    .single-post-template .content-col .post-meta .post-meta-item__cats a {
      display: flex;
      padding-left: 10px;
      margin-left: 10px;
      border-left: 1px solid #c2c2c2; }
  .single-post-template .content-col .post-meta .post-meta-item__tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px; }
    .single-post-template .content-col .post-meta .post-meta-item__tags a {
      padding-left: 10px;
      margin-left: 10px;
      border-left: 1px solid #c2c2c2; }
      .single-post-template .content-col .post-meta .post-meta-item__tags a:last-child {
        border-right: 0;
        padding-right: 0;
        margin-right: 0; }

.single-post-template .content-col .post-date {
  margin-top: 10px; }

.single-post-template .content-col .post-thumbnail {
  float: left;
  max-width: 350px;
  margin-right: 50px;
  margin-bottom: 35px; }
  .single-post-template .content-col .post-thumbnail img {
    display: block;
    width: 100%;
    height: auto; }

.single-post-template[data-layout=sidebar-content] .content-col .post-thumbnail, .single-post-template[data-layout=content-sidebar] .content-col .post-thumbnail {
  width: 250px;
  margin-right: 30px;
  margin-bottom: 15px; }

.comments-wrap {
  padding-top: 30px;
  display: block;
  width: 100%;
  font-size: 16px; }
  .comments-wrap.commented {
    border-top: 1px #CCCCCC solid; }
  .comments-wrap .logged-in-as-wrapper {
    width: 100%; }
  .comments-wrap .logged-in-as {
    margin-bottom: 25px;
    font-size: 16px; }
    .comments-wrap .logged-in-as .logged-user {
      margin-left: 6px; }
    .comments-wrap .logged-in-as .logout-btn {
      margin-left: 10px;
      color: #bb8844; }
  .comments-wrap .comment-reply-title {
    color: #bb8844;
    font-style: normal; }
  .comments-wrap .policy-field {
    font-size: 16px;
    display: block;
    margin-top: 20px;
    max-width: 65%; }
  .comments-wrap .comment-form-wrap {
    border-top: 1px solid #ccc;
    padding-top: 30px; }
  .comments-wrap .comment-form {
    margin-top: 15px; }
    .comments-wrap .comment-form .form-group {
      width: 100%;
      margin-bottom: 25px; }
      .comments-wrap .comment-form .form-group input {
        border: 1px solid #8E8E8E;
        width: 100%;
        padding: 0 15px;
        height: 45px;
        color: #333;
        z-index: 16; }
      .comments-wrap .comment-form .form-group .form-label {
        margin-bottom: 4px;
        display: inline-flex;
        align-items: center; }
    .comments-wrap .comment-form .textarea-comment {
      width: 100%;
      border: 1px solid #8E8E8E;
      resize: vertical;
      padding: 15px;
      min-height: 100px;
      max-height: 300px;
      color: #333;
      z-index: 16; }
    .comments-wrap .comment-form .submit-btn {
      margin-top: 15px;
      min-width: 180px;
      text-align: center;
      justify-content: center; }
  .comments-wrap .comment {
    list-style: none; }
    .comments-wrap .comment .comment-wrap {
      margin-bottom: 35px; }
      .comments-wrap .comment .comment-wrap .comment-header {
        display: flex;
        align-items: center;
        margin-bottom: 15px; }
        .comments-wrap .comment .comment-wrap .comment-header .comment-avatar {
          width: 70px;
          height: 70px; }
        .comments-wrap .comment .comment-wrap .comment-header .comment-info {
          padding-left: 15px; }
          .comments-wrap .comment .comment-wrap .comment-header .comment-info .author-title {
            font-weight: bold;
            font-size: 16px;
            display: inline-block;
            margin: 0; }
          .comments-wrap .comment .comment-wrap .comment-header .comment-info .comment-date {
            margin-top: 5px;
            font-size: 14px;
            display: flex;
            align-items: center; }
            .comments-wrap .comment .comment-wrap .comment-header .comment-info .comment-date .comment-reply-link {
              display: flex;
              align-items: center; }
              .comments-wrap .comment .comment-wrap .comment-header .comment-info .comment-date .comment-reply-link .slash-divider {
                margin: 0 10px; }
          .comments-wrap .comment .comment-wrap .comment-header .comment-info a {
            color: #bb8844; }
      .comments-wrap .comment .comment-wrap .comment-body .comment-text {
        font-size: 16px; }
        .comments-wrap .comment .comment-wrap .comment-body .comment-text p {
          font-size: 16px; }
        .comments-wrap .comment .comment-wrap .comment-body .comment-text .wait {
          color: #bb8844;
          margin-bottom: 15px; }
  @media (max-width: 991px) {
    .comments-wrap .logged-in-as-wrapper {
      justify-content: flex-start;
      margin-top: 0;
      margin-bottom: 25px; }
    .comments-wrap .policy-field {
      max-width: 100%; }
    .comments-wrap .logged-in-as {
      max-width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left; } }

/************************
        404 Error Styles
*************************/
.wrap-404 {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 180px;
  padding-bottom: 120px; }

.wrap-404 .error-caption {
  font-size: 240px;
  font-family: "Montserrat", sans-serif;
  color: #7b7b7b;
  line-height: 1;
  text-align: center;
  font-weight: 500; }

.wrap-404 .error-title {
  margin-bottom: 0;
  margin-top: 35px;
  line-height: 1;
  font-size: 50px;
  font-family: "Raleway", sans-serif;
  color: #7b7b7b;
  text-align: center;
  font-weight: 500; }

.wrap-404 .error-subtitle {
  margin-top: 32px;
  margin-bottom: 0;
  font-size: 24px;
  font-family: "Raleway", sans-serif;
  color: #7b7b7b;
  line-height: 1.2;
  text-align: center; }

.wrap-404 .btn-wrap {
  margin-top: 75px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

@media (max-width: 1400px) {
  .wrap-404 {
    padding-top: 120px;
    padding-bottom: 100px; }
  .wrap-404 .error-caption {
    font-size: 140px; }
  .wrap-404 .error-title {
    font-size: 36px; }
  .wrap-404 .error-subtitle {
    margin-top: 26px;
    font-size: 18px; }
  .wrap-404 .btn-wrap {
    margin-top: 55px; } }

@media (max-width: 767px) {
  .wrap-404 .error-caption {
    font-size: 120px; }
  .wrap-404 .error-title {
    font-size: 30px;
    line-height: 1.2; }
  .wrap-404 .error-subtitle {
    margin-top: 26px;
    font-size: 18px;
    line-height: 1.5; }
  .wrap-404 .btn-wrap {
    margin-top: 55px; } }

.staff-single-page-template .content-col .default-page-content .staff-thumbnail {
  float: left;
  margin-right: 35px; }
  .staff-single-page-template .content-col .default-page-content .staff-thumbnail img {
    height: auto;
    max-width: 240px;
    margin-right: 5%;
    padding-bottom: 20px; }
  @media (min-width: 660px) {
    .staff-single-page-template .content-col .default-page-content .staff-thumbnail + * {
      margin-top: 0; } }
  @media (max-width: 660px) {
    .staff-single-page-template .content-col .default-page-content .staff-thumbnail {
      float: none; }
      .staff-single-page-template .content-col .default-page-content .staff-thumbnail img {
        max-width: 100%; } }

html {
  background-color: #fff; }

body {
  background-color: #fff; }
  body.wait, body.wait * {
    cursor: wait; }

.page-template {
  background-color: #fff; }

.site-template-wrapper {
  background-color: #fff;
  max-width: 100vw;
  overflow-x: hidden;
  width: 100%; }

html {
  max-width: 100vw;
  overflow-x: hidden; }

.hide, .hidden {
  display: none; }

.wide {
  width: 100%;
  max-width: 100%; }

.internal-page-template {
  background-color: #fff;
  padding: 65px 0; }
  .internal-page-template[data-layout="content-sidebar"] .page-row {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .internal-page-template[data-layout="content-sidebar"] .page-row .content-col {
      width: calc(65% - 30px);
      padding-left: 15px; }
    .internal-page-template[data-layout="content-sidebar"] .page-row .sidebar-col {
      padding-right: 15px;
      width: calc(35% - 30px); }
  @media (max-width: 991px) {
    .internal-page-template[data-layout="content-sidebar"] .page-row .content-col {
      margin-left: auto;
      margin-right: auto;
      width: calc(100%);
      padding: 0 15px; }
    .internal-page-template[data-layout="content-sidebar"] .page-row .sidebar-col {
      margin-left: auto;
      margin-right: auto;
      width: calc(100%);
      padding: 0 15px; } }
  .internal-page-template[data-layout="sidebar-content"] .page-row {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between; }
    .internal-page-template[data-layout="sidebar-content"] .page-row .content-col {
      padding-right: 15px;
      width: calc(65% - 30px); }
    .internal-page-template[data-layout="sidebar-content"] .page-row .sidebar-col {
      padding-left: 15px;
      width: calc(35% - 30px); }
  @media (max-width: 991px) {
    .internal-page-template[data-layout="sidebar-content"] .page-row .content-col {
      margin-left: auto;
      margin-right: auto;
      width: calc(100%);
      padding: 0 15px; }
    .internal-page-template[data-layout="sidebar-content"] .page-row .sidebar-col {
      width: calc(100%);
      padding: 0 15px; } }
  .internal-page-template[data-layout="full-width-content"] .page-row {
    justify-content: space-between; }
    .internal-page-template[data-layout="full-width-content"] .page-row .left-col {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      padding: 0 15px; }
    .internal-page-template[data-layout="full-width-content"] .page-row .right-col {
      display: none; }
  @media (max-width: 767px) {
    .internal-page-template[data-layout="full-width-content"] .page-row .content-col {
      margin-left: auto;
      margin-right: auto;
      width: 100%; }
    .internal-page-template[data-layout="full-width-content"] .page-row .sidebar-col {
      margin-left: auto;
      margin-right: auto;
      width: 100%; } }
  .internal-page-template .content-col {
    width: 100%; }
  .internal-page-template .sidebar-col {
    width: 100%; }
    .internal-page-template .sidebar-col .textwidget {
      text-align: center; }
  .internal-page-template .page-title {
    z-index: 10;
    display: block;
    margin-bottom: 0.35em; }

.default-page-content {
  padding: 30px 0; }
  .default-page-content h1, .default-page-content h2, .default-page-content h3, .default-page-content h4, .default-page-content h5, .default-page-content h6 {
    margin-top: 25px;
    margin-bottom: 25px; }
  .default-page-content > h1:first-child, .default-page-content h2:first-child, .default-page-content h3:first-child, .default-page-content h4:first-child, .default-page-content h5:first-child, .default-page-content h6:first-child {
    margin-top: 0; }
  .default-page-content ul, .default-page-content ol {
    margin-bottom: 20px;
    padding-inline-start: 40px;
    list-style: disc; }
  .default-page-content .contact-button-wrap {
    margin-top: 20px; }

#wpadminbar {
  position: fixed !important;
  overflow: hidden; }

@media (min-width: 783px) {
  body.nav_style_a.desktop_nav_fixed {
    padding-top: calc(100px); }
    body.nav_style_a.desktop_nav_fixed .desktop-navigation[data-style="a"] {
      top: 0; }
      body.nav_style_a.desktop_nav_fixed .desktop-navigation[data-style="a"].active {
        top: 0;
        position: fixed; }
  body.nav_style_a.desktop_nav_static {
    padding-top: calc(100px); }
    body.nav_style_a.desktop_nav_static .desktop-navigation[data-style="a"] {
      top: 0; }
  body.nav_style_a.admin-bar {
    padding-top: calc(100px + 32px); }
    body.nav_style_a.admin-bar .desktop-navigation[data-style="a"] {
      top: calc(32px); }
  body.nav_style_a.desktop_nav_fixed.admin-bar {
    padding-top: calc(100px + 32px); }
    body.nav_style_a.desktop_nav_fixed.admin-bar .desktop-navigation[data-style="a"] {
      top: calc(32px); }
  body.nav_style_a.top_bar_desktop_on.top_bar_static {
    padding-top: calc(100px + 56px); }
    body.nav_style_a.top_bar_desktop_on.top_bar_static .top-bar {
      top: 0; }
    body.nav_style_a.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="a"] {
      top: calc(56px); }
      body.nav_style_a.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="a"].active {
        top: 0; }
  body.nav_style_a.top_bar_desktop_on.top_bar_fixed {
    padding-top: calc(100px + 56px); }
    body.nav_style_a.top_bar_desktop_on.top_bar_fixed .top-bar {
      top: 0; }
    body.nav_style_a.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="a"] {
      top: calc(56px); }
      body.nav_style_a.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="a"].active {
        top: 56px;
        position: fixed; }
  body.nav_style_a.admin-bar.top_bar_desktop_on.top_bar_fixed {
    padding-top: calc(100px + 32px + 56px); }
    body.nav_style_a.admin-bar.top_bar_desktop_on.top_bar_fixed .top-bar {
      top: 32px; }
    body.nav_style_a.admin-bar.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="a"] {
      top: calc(32px + 56px); }
      body.nav_style_a.admin-bar.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="a"].active {
        top: calc(32px + 56px);
        position: fixed; }
  body.nav_style_a.admin-bar.top_bar_desktop_on.top_bar_static {
    padding-top: calc(100px + 32px + 56px); }
    body.nav_style_a.admin-bar.top_bar_desktop_on.top_bar_static .top-bar {
      top: 32px; }
    body.nav_style_a.admin-bar.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="a"] {
      top: calc(32px + 56px); }
      body.nav_style_a.admin-bar.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="a"].active {
        top: 32px; } }

@media (max-width: 1024px) {
  body.nav_style_a.desktop_nav_fixed.top_bar_mobile_on.top_bar_static.admin-bar {
    padding-top: calc(32px + 56px + 100px); }
    body.nav_style_a.desktop_nav_fixed.top_bar_mobile_on.top_bar_static.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_a.desktop_nav_fixed.top_bar_mobile_on.top_bar_static.admin-bar .desktop-navigation[data-style="a"] {
      top: calc(32px + 56px); }
      body.nav_style_a.desktop_nav_fixed.top_bar_mobile_on.top_bar_static.admin-bar .desktop-navigation[data-style="a"].active {
        top: 32px; } }

@media (max-width: 782px) {
  body.desktop_nav_fixed .desktop-navigation[data-style="a"].active {
    position: fixed; }
  body.nav_style_a {
    padding-top: calc(100px); }
    body.nav_style_a .desktop-navigation[data-style="a"] {
      top: 0; }
  body.nav_style_a.admin-bar {
    padding-top: calc(100px + 46px); }
    body.nav_style_a.admin-bar .desktop-navigation[data-style="a"] {
      top: calc(46px); }
  body.nav_style_a.top_bar_mobile_on.top_bar_static {
    padding-top: calc(100px + 56px); }
    body.nav_style_a.top_bar_mobile_on.top_bar_static .top-bar {
      top: 0; }
    body.nav_style_a.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="a"] {
      top: calc(56px); }
      body.nav_style_a.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="a"].active {
        top: 0; }
  body.nav_style_a.top_bar_mobile_on.top_bar_fixed {
    padding-top: calc(100px + 56px); }
    body.nav_style_a.top_bar_mobile_on.top_bar_fixed .top-bar {
      top: 0; }
    body.nav_style_a.top_bar_mobile_on.top_bar_fixed .desktop-navigation[data-style="a"] {
      top: calc(56px); }
  body.nav_style_a.admin-bar.top_bar_mobile_on.top_bar_static {
    padding-top: calc(100px + 46px + 56px); }
    body.nav_style_a.admin-bar.top_bar_mobile_on.top_bar_static .top-bar {
      top: 32px; }
    body.nav_style_a.admin-bar.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="a"] {
      top: calc(32px + 56px); }
      body.nav_style_a.admin-bar.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="a"].active {
        top: 46px; }
  body.nav_style_a.admin-bar.top_bar_mobile_on.top_bar_fixed {
    padding-top: calc(100px + 46px + 56px); }
    body.nav_style_a.admin-bar.top_bar_mobile_on.top_bar_fixed .top-bar {
      top: 46px; }
    body.nav_style_a.admin-bar.top_bar_mobile_on.top_bar_fixed .desktop-navigation[data-style="a"] {
      top: calc(46px + 56px); }
  body.nav_style_a.desktop_nav_fixed.top_bar_mobile_on.top_bar_static.admin-bar {
    padding-top: calc(46px + 56px + 100px); }
    body.nav_style_a.desktop_nav_fixed.top_bar_mobile_on.top_bar_static.admin-bar .top-bar {
      top: 46px; }
    body.nav_style_a.desktop_nav_fixed.top_bar_mobile_on.top_bar_static.admin-bar .desktop-navigation[data-style="a"] {
      top: calc(46px + 56px); }
      body.nav_style_a.desktop_nav_fixed.top_bar_mobile_on.top_bar_static.admin-bar .desktop-navigation[data-style="a"].active {
        top: 46px; } }

@media (min-width: 783px) {
  body.nav_style_e.desktop_nav_fixed {
    padding-top: 100px; }
    body.nav_style_e.desktop_nav_fixed .desktop-navigation[data-style="e"].active {
      top: 0;
      position: fixed; }
  body.nav_style_e.desktop_nav_static {
    padding-top: calc(100px); }
    body.nav_style_e.desktop_nav_static .desktop-navigation[data-style="e"] {
      top: 0; }
  body.nav_style_e.admin-bar {
    padding-top: calc(100px + 32px); }
    body.nav_style_e.admin-bar .desktop-navigation[data-style="e"] {
      top: calc(32px); }
  body.nav_style_e.desktop_nav_fixed.admin-bar {
    padding-top: calc(100px + 32px); }
    body.nav_style_e.desktop_nav_fixed.admin-bar .desktop-navigation[data-style="e"] {
      top: calc(32px); }
  body.nav_style_e.top_bar_desktop_on.top_bar_static {
    padding-top: calc(100px + 56px); }
    body.nav_style_e.top_bar_desktop_on.top_bar_static .top-bar {
      top: 0; }
    body.nav_style_e.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="e"] {
      top: calc(56px); }
      body.nav_style_e.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="e"].active {
        top: 0; }
  body.nav_style_e.top_bar_desktop_on.top_bar_fixed {
    padding-top: calc(100px + 56px); }
    body.nav_style_e.top_bar_desktop_on.top_bar_fixed .top-bar {
      top: 0; }
    body.nav_style_e.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="e"] {
      top: calc(56px); }
  body.nav_style_e.admin-bar.top_bar_desktop_on.top_bar_static {
    padding-top: calc(100px + 32px + 56px); }
    body.nav_style_e.admin-bar.top_bar_desktop_on.top_bar_static .top-bar {
      top: 32px; }
    body.nav_style_e.admin-bar.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="e"] {
      top: calc(32px + 56px); }
      body.nav_style_e.admin-bar.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="e"].active {
        top: 32px; }
  body.nav_style_e.admin-bar.top_bar_desktop_on.top_bar_fixed {
    padding-top: calc(100px + 32px + 56px); }
    body.nav_style_e.admin-bar.top_bar_desktop_on.top_bar_fixed .top-bar {
      top: 32px; }
    body.nav_style_e.admin-bar.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="e"] {
      top: calc(32px + 56px); } }

@media (max-width: 1024px) {
  body.nav_style_e.desktop_nav_fixed.top_bar_mobile_on.top_bar_static.admin-bar {
    padding-top: calc(32px + 56px + 100px); }
    body.nav_style_e.desktop_nav_fixed.top_bar_mobile_on.top_bar_static.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_e.desktop_nav_fixed.top_bar_mobile_on.top_bar_static.admin-bar .desktop-navigation[data-style="e"] {
      top: calc(32px + 56px); }
      body.nav_style_e.desktop_nav_fixed.top_bar_mobile_on.top_bar_static.admin-bar .desktop-navigation[data-style="e"].active {
        top: 32px; } }

@media (max-width: 782px) {
  body.desktop_nav_fixed .desktop-navigation[data-style="e"].active {
    position: fixed; }
  body.nav_style_e {
    padding-top: calc(100px); }
    body.nav_style_e .desktop-navigation[data-style="e"] {
      top: 0; }
  body.nav_style_e.admin-bar {
    padding-top: calc(100px + 46px); }
    body.nav_style_e.admin-bar .desktop-navigation[data-style="e"] {
      top: calc(46px); }
  body.nav_style_e.top_bar_mobile_on.top_bar_static {
    padding-top: calc(100px + 56px); }
    body.nav_style_e.top_bar_mobile_on.top_bar_static .top-bar {
      top: 0; }
    body.nav_style_e.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="e"] {
      top: calc(56px); }
      body.nav_style_e.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="e"].active {
        top: 0; }
  body.nav_style_e.top_bar_mobile_on.top_bar_fixed {
    padding-top: calc(100px + 56px); }
    body.nav_style_e.top_bar_mobile_on.top_bar_fixed .top-bar {
      top: 0; }
    body.nav_style_e.top_bar_mobile_on.top_bar_fixed .desktop-navigation[data-style="e"] {
      top: calc(56px); }
  body.nav_style_e.admin-bar.top_bar_mobile_on.top_bar_static {
    padding-top: calc(100px + 46px + 56px); }
    body.nav_style_e.admin-bar.top_bar_mobile_on.top_bar_static .top-bar {
      top: 32px; }
    body.nav_style_e.admin-bar.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="e"] {
      top: calc(32px + 56px); }
      body.nav_style_e.admin-bar.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="e"].active {
        top: 46px; }
  body.nav_style_e.admin-bar.top_bar_mobile_on.top_bar_fixed {
    padding-top: calc(100px + 46px + 56px); }
    body.nav_style_e.admin-bar.top_bar_mobile_on.top_bar_fixed .top-bar {
      top: 46px; }
    body.nav_style_e.admin-bar.top_bar_mobile_on.top_bar_fixed .desktop-navigation[data-style="e"] {
      top: calc(46px + 56px); } }

@media (min-width: 1025px) {
  body.nav_style_b.desktop_nav_static.b_nav_position_above {
    padding-top: calc(50px + 254px); }
    body.nav_style_b.desktop_nav_static.b_nav_position_above .desktop-navigation[data-style="b"] {
      top: 0; }
    body.nav_style_b.desktop_nav_static.b_nav_position_above .nav-header[data-style="b"] {
      top: 50px; }
  body.nav_style_b.desktop_nav_static.b_nav_position_below {
    padding-top: calc(50px + 254px); }
    body.nav_style_b.desktop_nav_static.b_nav_position_below .desktop-navigation[data-style="b"] {
      top: calc(254px); }
    body.nav_style_b.desktop_nav_static.b_nav_position_below .nav-header[data-style="b"] {
      top: 0; }
  body.nav_style_b.desktop_nav_fixed.b_nav_position_above {
    padding-top: calc(50px + 254px); }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_above .desktop-navigation[data-style="b"] {
      top: 0; }
      body.nav_style_b.desktop_nav_fixed.b_nav_position_above .desktop-navigation[data-style="b"].active {
        top: 0;
        position: fixed; }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_above .nav-header[data-style="b"] {
      top: calc(50px); }
  body.nav_style_b.desktop_nav_fixed.b_nav_position_below {
    padding-top: calc(50px + 254px); }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below .desktop-navigation[data-style="b"] {
      top: 254px; }
      body.nav_style_b.desktop_nav_fixed.b_nav_position_below .desktop-navigation[data-style="b"].active {
        position: fixed;
        top: 0; }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below .nav-header[data-style="b"] {
      top: 0; }
  body.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_fixed {
    padding-top: calc(56px + 50px + 254px); }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="b"] {
      top: 56px; }
      body.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="b"].active {
        position: fixed;
        top: 56px; }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_fixed .nav-header[data-style="b"] {
      top: calc(50px + 56px); }
  body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed {
    padding-top: calc(56px + 50px + 254px); }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="b"] {
      top: calc(56px + 254px); }
      body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="b"].active {
        position: fixed;
        top: 56px; }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed .nav-header[data-style="b"] {
      top: calc(56px); }
  body.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_static {
    padding-top: calc(56px + 50px + 254px); }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="b"] {
      top: 56px; }
      body.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="b"].active {
        position: fixed;
        top: 0; }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_static .nav-header[data-style="b"] {
      top: calc(50px + 56px); }
  body.nav_style_b.desktop_nav_static.b_nav_position_below.top_bar_desktop_on.top_bar_fixed {
    padding-top: calc(56px + 50px + 254px); }
    body.nav_style_b.desktop_nav_static.b_nav_position_below.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="b"] {
      top: calc(56px + 254px); }
      body.nav_style_b.desktop_nav_static.b_nav_position_below.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="b"].active {
        position: fixed;
        top: 56px; }
    body.nav_style_b.desktop_nav_static.b_nav_position_below.top_bar_desktop_on.top_bar_fixed .nav-header[data-style="b"] {
      top: calc(56px); }
  body.nav_style_b.desktop_nav_static.b_nav_position_above.admin-bar {
    padding-top: calc(32px + 50px + 254px); }
    body.nav_style_b.desktop_nav_static.b_nav_position_above.admin-bar .desktop-navigation[data-style="b"] {
      top: calc(32px); }
    body.nav_style_b.desktop_nav_static.b_nav_position_above.admin-bar .nav-header[data-style="b"] {
      top: calc(32px + 50px); }
  body.nav_style_b.desktop_nav_fixed.b_nav_position_above.admin-bar {
    padding-top: calc(32px + 50px + 254px); }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_above.admin-bar .desktop-navigation[data-style="b"] {
      top: calc(32px); }
      body.nav_style_b.desktop_nav_fixed.b_nav_position_above.admin-bar .desktop-navigation[data-style="b"].active {
        position: fixed;
        top: 32px; }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_above.admin-bar .nav-header[data-style="b"] {
      top: calc(32px + 50px); }
  body.nav_style_b.desktop_nav_static.b_nav_position_below.admin-bar {
    padding-top: calc(32px + 50px + 254px); }
    body.nav_style_b.desktop_nav_static.b_nav_position_below.admin-bar .desktop-navigation[data-style="b"] {
      top: calc(32px + 254px); }
    body.nav_style_b.desktop_nav_static.b_nav_position_below.admin-bar .nav-header[data-style="b"] {
      top: 32px; }
  body.nav_style_b.desktop_nav_fixed.b_nav_position_below.admin-bar {
    padding-top: calc(32px + 50px + 254px); }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below.admin-bar .desktop-navigation[data-style="b"] {
      top: calc(32px + 254px); }
      body.nav_style_b.desktop_nav_fixed.b_nav_position_below.admin-bar .desktop-navigation[data-style="b"].active {
        position: fixed;
        top: 32px; }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below.admin-bar .nav-header[data-style="b"] {
      top: 32px; }
  body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_fixed, body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_static {
    padding-top: calc(56px + 254px + 50px); }
    body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_fixed .top-bar, body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_static .top-bar {
      top: 0; }
    body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="b"], body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="b"] {
      top: calc(56px + 254px); }
    body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_fixed .nav-header[data-style="b"], body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_static .nav-header[data-style="b"] {
      top: 56px; }
  body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar, body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar {
    padding-top: calc(32px + 56px + 254px + 50px); }
    body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar .top-bar, body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="b"], body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="b"] {
      top: calc(56px + 254px + 32px); }
    body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar .nav-header[data-style="b"], body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .nav-header[data-style="b"] {
      top: calc(56px + 32px); }
  body.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar {
    padding-top: calc(32px + 56px + 254px + 50px); }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="b"] {
      top: calc(56px + 32px); }
      body.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="b"].active {
        top: calc(56px + 32px); }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar .nav-header[data-style="b"] {
      top: calc(50px + 56px + 32px); }
  body.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar {
    padding-top: calc(32px + 56px + 254px + 50px); }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="b"] {
      top: calc(56px + 32px); }
      body.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="b"].active {
        top: calc(32px); }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .nav-header[data-style="b"] {
      top: calc(50px + 56px + 32px); }
  body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar {
    padding-top: calc(32px + 56px + 254px + 50px); }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="b"] {
      top: calc(56px + 32px); }
      body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="b"].active {
        top: calc(32px); }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .nav-header[data-style="b"] {
      top: calc(50px + 56px + 32px); }
  body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_static {
    padding-top: calc(56px + 254px + 50px); }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_static .top-bar {
      top: 0; }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="b"] {
      top: calc(56px + 254px); }
      body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="b"].active {
        top: 0; }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_static .nav-header[data-style="b"] {
      top: calc(56px); }
  body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed {
    padding-top: calc(56px + 254px + 50px); }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed .top-bar {
      top: 0; }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="b"] {
      top: calc(56px + 254px); }
      body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="b"].active {
        top: 56px; }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed .nav-header[data-style="b"] {
      top: calc(56px); }
  body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar, body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar {
    padding-top: calc(32px + 56px + 254px + 50px); }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .top-bar, body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="b"], body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="b"] {
      top: calc(56px + 254px + 32px); }
      body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="b"].active, body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="b"].active {
        top: calc(32px); }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .nav-header[data-style="b"], body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .nav-header[data-style="b"] {
      top: calc(56px + 32px); }
  body.nav_style_b.desktop_nav_static.b_nav_position_below.top_bar_desktop_on.top_bar_static {
    padding-top: calc(56px + 254px + 50px); }
    body.nav_style_b.desktop_nav_static.b_nav_position_below.top_bar_desktop_on.top_bar_static .top-bar {
      top: 0; }
    body.nav_style_b.desktop_nav_static.b_nav_position_below.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="b"] {
      top: calc(254px + 56px); }
    body.nav_style_b.desktop_nav_static.b_nav_position_below.top_bar_desktop_on.top_bar_static .nav-header[data-style="b"] {
      top: calc(56px); }
  body.nav_style_b.desktop_nav_static.b_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar {
    padding-top: calc(32px + 56px + 254px + 50px); }
    body.nav_style_b.desktop_nav_static.b_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_b.desktop_nav_static.b_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="b"] {
      top: calc(32px + 254px + 56px); }
    body.nav_style_b.desktop_nav_static.b_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .nav-header[data-style="b"] {
      top: calc(32px + 56px); }
  body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_static {
    padding-top: calc(56px + 254px + 50px); }
    body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_static .top-bar {
      top: 0; }
    body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="b"] {
      top: calc(56px); }
    body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_static .nav-header[data-style="b"] {
      top: calc(50px + 56px); }
  body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar {
    padding-top: calc(32px + 56px + 254px + 50px); }
    body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="b"] {
      top: calc(32px + 56px); }
    body.nav_style_b.desktop_nav_static.b_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .nav-header[data-style="b"] {
      top: calc(32px + 50px + 56px); }
  body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar {
    padding-top: calc(32px + 56px + 254px + 50px); }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="b"] {
      top: calc(32px + 56px + 254px); }
      body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="b"].active {
        top: calc(32px + 56px); }
    body.nav_style_b.desktop_nav_fixed.b_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .nav-header[data-style="b"] {
      top: calc(32px + 56px); } }

@media (max-width: 1024px) {
  body.desktop_nav_fixed .desktop-navigation[data-style="b"].active {
    position: fixed; }
  body.nav_style_b {
    padding-top: calc(100px); }
    body.nav_style_b .desktop-navigation[data-style="b"] {
      top: 0; }
  body.nav_style_b.admin-bar {
    padding-top: calc(100px + 32px); }
    body.nav_style_b.admin-bar .desktop-navigation[data-style="b"] {
      top: calc(32px); }
  body.nav_style_b.top_bar_mobile_on.top_bar_static {
    padding-top: calc(100px + 56px); }
    body.nav_style_b.top_bar_mobile_on.top_bar_static .top-bar {
      top: 0; }
    body.nav_style_b.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="b"] {
      top: calc(56px); }
      body.nav_style_b.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="b"].active {
        top: 0; }
  body.nav_style_b.top_bar_mobile_on.top_bar_fixed {
    padding-top: calc(100px + 56px); }
    body.nav_style_b.top_bar_mobile_on.top_bar_fixed .top-bar {
      top: 0; }
    body.nav_style_b.top_bar_mobile_on.top_bar_fixed .desktop-navigation[data-style="b"] {
      top: calc(56px); }
  body.nav_style_b.admin-bar.top_bar_mobile_on.top_bar_static {
    padding-top: calc(100px + 32px + 56px); }
    body.nav_style_b.admin-bar.top_bar_mobile_on.top_bar_static .top-bar {
      top: 32px; }
    body.nav_style_b.admin-bar.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="b"] {
      top: calc(32px + 56px); }
      body.nav_style_b.admin-bar.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="b"].active {
        top: 32px; }
  body.nav_style_b.admin-bar.top_bar_mobile_on.top_bar_fixed {
    padding-top: calc(100px + 32px + 56px); }
    body.nav_style_b.admin-bar.top_bar_mobile_on.top_bar_fixed .top-bar {
      top: 32px; }
    body.nav_style_b.admin-bar.top_bar_mobile_on.top_bar_fixed .desktop-navigation[data-style="b"] {
      top: calc(32px + 56px); } }

@media (max-width: 782px) {
  body.desktop_nav_fixed .desktop-navigation[data-style="b"].active {
    position: fixed; }
  body.nav_style_b {
    padding-top: calc(100px); }
    body.nav_style_b .desktop-navigation[data-style="b"] {
      top: 0; }
  body.nav_style_b.admin-bar {
    padding-top: calc(100px + 46px); }
    body.nav_style_b.admin-bar .desktop-navigation[data-style="b"] {
      top: calc(46px); }
  body.nav_style_b.top_bar_mobile_on.top_bar_static {
    padding-top: calc(100px + 56px); }
    body.nav_style_b.top_bar_mobile_on.top_bar_static .top-bar {
      top: 0; }
    body.nav_style_b.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="b"] {
      top: calc(56px); }
      body.nav_style_b.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="b"].active {
        top: 0; }
  body.nav_style_b.top_bar_mobile_on.top_bar_fixed {
    padding-top: calc(100px + 56px); }
    body.nav_style_b.top_bar_mobile_on.top_bar_fixed .top-bar {
      top: 0; }
    body.nav_style_b.top_bar_mobile_on.top_bar_fixed .desktop-navigation[data-style="b"] {
      top: calc(56px); }
  body.nav_style_b.admin-bar.top_bar_mobile_on.top_bar_static {
    padding-top: calc(100px + 46px + 56px); }
    body.nav_style_b.admin-bar.top_bar_mobile_on.top_bar_static .top-bar {
      top: 46px; }
    body.nav_style_b.admin-bar.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="b"] {
      top: calc(46px + 56px); }
      body.nav_style_b.admin-bar.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="b"].active {
        top: 46px; }
  body.nav_style_b.admin-bar.top_bar_mobile_on.top_bar_fixed {
    padding-top: calc(100px + 46px + 56px); }
    body.nav_style_b.admin-bar.top_bar_mobile_on.top_bar_fixed .top-bar {
      top: 46px; }
    body.nav_style_b.admin-bar.top_bar_mobile_on.top_bar_fixed .desktop-navigation[data-style="b"] {
      top: calc(46px + 56px); }
  body.nav_style_b.desktop_nav_fixed.top_bar_mobile_on.top_bar_fixed.admin-bar {
    padding-top: calc(46px + 56px + 100px); }
    body.nav_style_b.desktop_nav_fixed.top_bar_mobile_on.top_bar_fixed.admin-bar .top-bar {
      top: 46px; }
    body.nav_style_b.desktop_nav_fixed.top_bar_mobile_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="b"] {
      top: calc(46px + 56px); }
      body.nav_style_b.desktop_nav_fixed.top_bar_mobile_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="b"].active {
        top: calc(46px + 56px); } }

@media (min-width: 1025px) {
  body.nav_style_c.desktop_nav_static.c_nav_position_above {
    padding-top: calc(50px + 140px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_above .desktop-navigation[data-style="c"] {
      top: 0; }
    body.nav_style_c.desktop_nav_static.c_nav_position_above .nav-header[data-style="c"] {
      top: 50px; }
  body.nav_style_c.desktop_nav_static.c_nav_position_below {
    padding-top: calc(50px + 140px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_below .desktop-navigation[data-style="c"] {
      top: calc(140px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_below .nav-header[data-style="c"] {
      top: 0; }
  body.nav_style_c.desktop_nav_fixed.c_nav_position_above {
    padding-top: calc(50px + 140px); }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_above .desktop-navigation[data-style="c"] {
      top: 0; }
      body.nav_style_c.desktop_nav_fixed.c_nav_position_above .desktop-navigation[data-style="c"].active {
        top: 0;
        position: fixed; }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_above .nav-header[data-style="c"] {
      top: calc(50px); }
  body.nav_style_c.desktop_nav_fixed.c_nav_position_below {
    padding-top: calc(50px + 140px); }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_below .desktop-navigation[data-style="c"] {
      top: 140px; }
      body.nav_style_c.desktop_nav_fixed.c_nav_position_below .desktop-navigation[data-style="c"].active {
        position: fixed;
        top: 0; }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_below .nav-header[data-style="c"] {
      top: 0; }
  body.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_fixed {
    padding-top: calc(56px + 50px + 140px); }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="c"] {
      top: 56px; }
      body.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="c"].active {
        position: fixed;
        top: 56px; }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_fixed .nav-header[data-style="c"] {
      top: calc(50px + 56px); }
  body.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_fixed {
    padding-top: calc(56px + 50px + 140px); }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="c"] {
      top: calc(56px + 140px); }
      body.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="c"].active {
        position: fixed;
        top: 56px; }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_fixed .nav-header[data-style="c"] {
      top: calc(56px); }
  body.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_fixed {
    padding-top: calc(56px + 50px + 140px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="c"] {
      top: calc(56px + 140px); }
      body.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="c"].active {
        position: fixed;
        top: 56px; }
    body.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_fixed .nav-header[data-style="c"] {
      top: calc(56px); }
  body.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar {
    padding-top: calc(32px + 56px + 50px + 140px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="c"] {
      top: calc(32px + 56px + 140px); }
      body.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="c"].active {
        position: fixed;
        top: 32px; }
    body.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .nav-header[data-style="c"] {
      top: calc(32px + 56px); }
  body.nav_style_c.desktop_nav_static.c_nav_position_above.admin-bar {
    padding-top: calc(32px + 50px + 140px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_above.admin-bar .desktop-navigation[data-style="c"] {
      top: calc(32px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_above.admin-bar .nav-header[data-style="c"] {
      top: calc(32px + 50px); }
  body.nav_style_c.desktop_nav_fixed.c_nav_position_above.admin-bar {
    padding-top: calc(32px + 50px + 140px); }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_above.admin-bar .desktop-navigation[data-style="c"] {
      top: calc(32px); }
      body.nav_style_c.desktop_nav_fixed.c_nav_position_above.admin-bar .desktop-navigation[data-style="c"].active {
        position: fixed;
        top: 32px; }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_above.admin-bar .nav-header[data-style="c"] {
      top: calc(32px + 50px); }
  body.nav_style_c.desktop_nav_static.c_nav_position_below.admin-bar {
    padding-top: calc(32px + 50px + 140px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_below.admin-bar .desktop-navigation[data-style="c"] {
      top: calc(32px + 140px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_below.admin-bar .nav-header[data-style="c"] {
      top: 32px; }
  body.nav_style_c.desktop_nav_fixed.c_nav_position_below.admin-bar {
    padding-top: calc(32px + 50px + 140px); }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_below.admin-bar .desktop-navigation[data-style="c"] {
      top: calc(32px + 140px); }
      body.nav_style_c.desktop_nav_fixed.c_nav_position_below.admin-bar .desktop-navigation[data-style="c"].active {
        position: fixed;
        top: 32px; }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_below.admin-bar .nav-header[data-style="c"] {
      top: 32px; }
  body.nav_style_c.desktop_nav_static.c_nav_position_above.top_bar_desktop_on.top_bar_fixed {
    padding-top: calc(56px + 140px + 50px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_above.top_bar_desktop_on.top_bar_fixed .top-bar {
      top: 0; }
    body.nav_style_c.desktop_nav_static.c_nav_position_above.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="c"] {
      top: calc(56px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_above.top_bar_desktop_on.top_bar_fixed .nav-header[data-style="c"] {
      top: calc(56px + 50px); }
  body.nav_style_c.desktop_nav_static.c_nav_position_above.top_bar_desktop_on.top_bar_static {
    padding-top: calc(56px + 140px + 50px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_above.top_bar_desktop_on.top_bar_static .top-bar {
      top: 0; }
    body.nav_style_c.desktop_nav_static.c_nav_position_above.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="c"] {
      top: calc(56px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_above.top_bar_desktop_on.top_bar_static .nav-header[data-style="c"] {
      top: calc(56px + 50px); }
  body.nav_style_c.desktop_nav_static.c_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar {
    padding-top: calc(32px + 56px + 140px + 50px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_c.desktop_nav_static.c_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="c"] {
      top: calc(56px + 32px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar .nav-header[data-style="c"] {
      top: calc(56px + 32px + 50px); }
  body.nav_style_c.desktop_nav_static.c_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar {
    padding-top: calc(32px + 56px + 140px + 50px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_c.desktop_nav_static.c_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="c"] {
      top: calc(56px + 32px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .nav-header[data-style="c"] {
      top: calc(56px + 32px + 50px); }
  body.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar, body.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar {
    padding-top: calc(32px + 56px + 140px + 50px); }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar .top-bar, body.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="c"], body.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="c"] {
      top: calc(56px + 32px); }
      body.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="c"].active, body.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="c"].active {
        top: calc(32px); }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar .nav-header[data-style="c"], body.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .nav-header[data-style="c"] {
      top: calc(50px + 56px + 32px); }
  body.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_static {
    padding-top: calc(56px + 140px + 50px); }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_static .top-bar {
      top: 0; }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="c"] {
      top: 56px; }
      body.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="c"].active {
        position: fixed;
        top: 0; }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_above.top_bar_desktop_on.top_bar_static .nav-header[data-style="c"] {
      top: calc(50px + 56px); }
  body.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_static {
    padding-top: calc(56px + 50px + 140px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_static .top-bar {
      top: 0; }
    body.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="c"] {
      top: calc(140px + 56px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_static .nav-header[data-style="c"] {
      top: calc(56px); }
  body.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar {
    padding-top: calc(32px + 56px + 50px + 140px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="c"] {
      top: calc(32px + 140px + 56px); }
    body.nav_style_c.desktop_nav_static.c_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .nav-header[data-style="c"] {
      top: calc(32px + 56px); }
  body.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar {
    padding-top: calc(32px + 56px + 140px + 50px); }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="c"] {
      top: calc(56px + 140px + 32px); }
      body.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="c"].active {
        top: calc(56px + 32px); }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .nav-header[data-style="c"] {
      top: calc(56px + 32px); }
  body.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_static {
    padding-top: calc(56px + 140px + 50px); }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_static .top-bar {
      top: 0; }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="c"] {
      top: calc(56px + 140px); }
      body.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="c"].active {
        top: 0; }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_static .nav-header[data-style="c"] {
      top: calc(56px); }
  body.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar {
    padding-top: calc(32px + 56px + 140px + 50px); }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="c"] {
      top: calc(56px + 140px + 32px); }
      body.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="c"].active {
        top: calc(32px); }
    body.nav_style_c.desktop_nav_fixed.c_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .nav-header[data-style="c"] {
      top: calc(56px + 32px); } }

@media (max-width: 1024px) {
  body.desktop_nav_fixed .desktop-navigation[data-style="c"].active {
    position: fixed; }
  body.nav_style_c {
    padding-top: calc(100px); }
    body.nav_style_c .desktop-navigation[data-style="c"] {
      top: 0; }
  body.nav_style_c.admin-bar {
    padding-top: calc(100px + 32px); }
    body.nav_style_c.admin-bar .desktop-navigation[data-style="c"] {
      top: calc(32px); }
  body.nav_style_c.top_bar_mobile_on.top_bar_static {
    padding-top: calc(100px + 56px); }
    body.nav_style_c.top_bar_mobile_on.top_bar_static .top-bar {
      top: 0; }
    body.nav_style_c.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="c"] {
      top: calc(56px); }
      body.nav_style_c.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="c"].active {
        top: 0; }
  body.nav_style_c.top_bar_mobile_on.top_bar_fixed {
    padding-top: calc(100px + 56px); }
    body.nav_style_c.top_bar_mobile_on.top_bar_fixed .top-bar {
      top: 0; }
    body.nav_style_c.top_bar_mobile_on.top_bar_fixed .desktop-navigation[data-style="c"] {
      top: calc(56px); }
  body.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_static {
    padding-top: calc(100px + 32px + 56px); }
    body.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_static .top-bar {
      top: 32px; }
    body.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="c"] {
      top: calc(32px + 56px); }
      body.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="c"].active {
        top: 32px; }
  body.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_fixed {
    padding-top: calc(100px + 32px + 56px); }
    body.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_fixed .top-bar {
      top: 32px; }
    body.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_fixed .desktop-navigation[data-style="c"] {
      top: calc(32px + 56px); }
  body.nav_style_c.desktop_nav_fixed.top_bar_mobile_on.top_bar_static.admin-bar {
    padding-top: calc(32px + 56px + 100px); }
    body.nav_style_c.desktop_nav_fixed.top_bar_mobile_on.top_bar_static.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_c.desktop_nav_fixed.top_bar_mobile_on.top_bar_static.admin-bar .desktop-navigation[data-style="c"] {
      top: calc(32px + 56px); }
      body.nav_style_c.desktop_nav_fixed.top_bar_mobile_on.top_bar_static.admin-bar .desktop-navigation[data-style="c"].active {
        top: 32px; }
  body.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_fixed {
    padding-top: calc(100px + 32px + 56px); }
    body.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_fixed .top-bar {
      top: 32px; }
    body.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_fixed .desktop-navigation[data-style="c"] {
      top: calc(32px + 56px); }
  body.nav_style_c.desktop_nav_static.top_bar_mobile_on.top_bar_fixed.admin-bar {
    padding-top: calc(32px + 56px + 100px); }
    body.nav_style_c.desktop_nav_static.top_bar_mobile_on.top_bar_fixed.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_c.desktop_nav_static.top_bar_mobile_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="c"] {
      top: calc(32px + 56px); } }

@media (max-width: 782px) {
  body.desktop_nav_fixed .desktop-navigation[data-style="c"].active {
    position: fixed; }
  body.nav_style_c {
    padding-top: calc(100px); }
    body.nav_style_c .desktop-navigation[data-style="c"] {
      top: 0; }
  body.nav_style_c.admin-bar {
    padding-top: calc(100px + 46px); }
    body.nav_style_c.admin-bar .desktop-navigation[data-style="c"] {
      top: calc(46px); }
  body.nav_style_c.top_bar_mobile_on.top_bar_static {
    padding-top: calc(100px + 56px); }
    body.nav_style_c.top_bar_mobile_on.top_bar_static .top-bar {
      top: 0; }
    body.nav_style_c.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="c"] {
      top: calc(56px); }
      body.nav_style_c.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="c"].active {
        top: 0; }
  body.nav_style_c.top_bar_mobile_on.top_bar_fixed {
    padding-top: calc(100px + 56px); }
    body.nav_style_c.top_bar_mobile_on.top_bar_fixed .top-bar {
      top: 0; }
    body.nav_style_c.top_bar_mobile_on.top_bar_fixed .desktop-navigation[data-style="c"] {
      top: calc(56px); }
  body.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_static {
    padding-top: calc(100px + 46px + 56px); }
    body.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_static .top-bar {
      top: 46px; }
    body.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="c"] {
      top: calc(46px + 56px); }
      body.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="c"].active {
        top: 46px; }
  body.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_fixed {
    padding-top: calc(100px + 46px + 56px); }
    body.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_fixed .top-bar {
      top: 46px; }
    body.nav_style_c.admin-bar.top_bar_mobile_on.top_bar_fixed .desktop-navigation[data-style="c"] {
      top: calc(46px + 56px); } }

@media (min-width: 783px) {
  body.nav_style_d.desktop_nav_fixed.d_nav_position_above {
    padding-top: 82px; }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_above .desktop-navigation[data-style="d"] {
      top: 0;
      position: absolute; }
      body.nav_style_d.desktop_nav_fixed.d_nav_position_above .desktop-navigation[data-style="d"].active {
        top: 0;
        position: fixed; }
  body.nav_style_d.desktop_nav_fixed.d_nav_position_below .page-template {
    padding-top: 82px; }
  body.nav_style_d.desktop_nav_fixed.d_nav_position_below .desktop-navigation[data-style="d"] {
    top: auto;
    position: absolute; }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_below .desktop-navigation[data-style="d"].active {
      top: 0;
      position: fixed; }
  body.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_static {
    padding-top: calc(56px); }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_static .page-template {
      padding-top: 82px; }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="d"] {
      top: auto;
      position: absolute; }
      body.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="d"].active {
        top: 0;
        position: fixed; }
  body.nav_style_d.desktop_nav_fixed.d_nav_position_above.admin-bar {
    padding-top: calc(32px + 82px); }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_above.admin-bar .desktop-navigation[data-style="d"] {
      top: 32px;
      position: absolute; }
      body.nav_style_d.desktop_nav_fixed.d_nav_position_above.admin-bar .desktop-navigation[data-style="d"].active {
        top: 32px;
        position: fixed; }
  body.nav_style_d.desktop_nav_fixed.d_nav_position_below.admin-bar {
    padding-top: 32px; }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_below.admin-bar .page-template {
      padding-top: 82px; }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_below.admin-bar .desktop-navigation[data-style="d"] {
      top: auto;
      position: absolute; }
      body.nav_style_d.desktop_nav_fixed.d_nav_position_below.admin-bar .desktop-navigation[data-style="d"].active {
        top: calc(32px);
        position: fixed; }
  body.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar {
    padding-top: calc(32px + 56px); }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .page-template {
      padding-top: 82px; }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="d"] {
      top: auto;
      position: absolute; }
      body.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="d"].active {
        top: calc(32px);
        position: fixed; }
  body.nav_style_d.desktop_nav_static.d_nav_position_above.admin-bar {
    padding-top: calc(32px + 82px); }
    body.nav_style_d.desktop_nav_static.d_nav_position_above.admin-bar .desktop-navigation[data-style="d"] {
      top: 32px;
      position: absolute; }
      body.nav_style_d.desktop_nav_static.d_nav_position_above.admin-bar .desktop-navigation[data-style="d"].active {
        top: 32px;
        position: fixed; }
  body.nav_style_d.desktop_nav_static.d_nav_position_below.admin-bar {
    padding-top: 32px; }
    body.nav_style_d.desktop_nav_static.d_nav_position_below.admin-bar .page-template {
      padding-top: 82px; }
    body.nav_style_d.desktop_nav_static.d_nav_position_below.admin-bar .desktop-navigation[data-style="d"] {
      top: auto;
      position: absolute; }
      body.nav_style_d.desktop_nav_static.d_nav_position_below.admin-bar .desktop-navigation[data-style="d"].active {
        top: calc(32px);
        position: fixed; }
  body.nav_style_d.desktop_nav_fixed.d_nav_position_above.top_bar_desktop_on.top_bar_fixed {
    padding-top: calc(56px + 82px); }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_above.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="d"] {
      top: 56px;
      position: absolute; }
      body.nav_style_d.desktop_nav_fixed.d_nav_position_above.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="d"].active {
        top: 56px;
        position: fixed; }
  body.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_fixed {
    padding-top: 56px; }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_fixed .page-template {
      padding-top: 82px; }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="d"] {
      top: auto;
      position: absolute; }
      body.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="d"].active {
        top: calc(56px);
        position: fixed; }
  body.nav_style_d.desktop_nav_static.d_nav_position_above.top_bar_desktop_on.top_bar_fixed {
    padding-top: calc(56px + 82px); }
    body.nav_style_d.desktop_nav_static.d_nav_position_above.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="d"] {
      top: 56px;
      position: absolute; }
      body.nav_style_d.desktop_nav_static.d_nav_position_above.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="d"].active {
        top: 56px;
        position: fixed; }
  body.nav_style_d.desktop_nav_static.d_nav_position_below.top_bar_desktop_on.top_bar_fixed {
    padding-top: 56px; }
    body.nav_style_d.desktop_nav_static.d_nav_position_below.top_bar_desktop_on.top_bar_fixed .page-template {
      padding-top: 82px; }
    body.nav_style_d.desktop_nav_static.d_nav_position_below.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="d"] {
      top: auto;
      position: absolute; }
      body.nav_style_d.desktop_nav_static.d_nav_position_below.top_bar_desktop_on.top_bar_fixed .desktop-navigation[data-style="d"].active {
        top: calc(56px);
        position: fixed; }
  body.nav_style_d.desktop_nav_fixed.d_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar {
    padding-top: calc(32px + 56px + 82px); }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="d"] {
      top: calc(32px + 56px);
      position: absolute; }
      body.nav_style_d.desktop_nav_fixed.d_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="d"].active {
        top: calc(32px + 56px);
        position: fixed; }
  body.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar {
    padding-top: calc(32px + 56px); }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .page-template {
      padding-top: 82px; }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="d"] {
      top: auto;
      position: absolute; }
      body.nav_style_d.desktop_nav_fixed.d_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="d"].active {
        top: calc(32px + 56px);
        position: fixed; }
  body.nav_style_d.desktop_nav_static.d_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar {
    padding-top: calc(32px + 56px + 82px); }
    body.nav_style_d.desktop_nav_static.d_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_d.desktop_nav_static.d_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="d"] {
      top: calc(32px + 56px);
      position: absolute; }
      body.nav_style_d.desktop_nav_static.d_nav_position_above.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="d"].active {
        top: calc(32px + 56px);
        position: fixed; }
  body.nav_style_d.desktop_nav_static.d_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar {
    padding-top: calc(32px + 56px); }
    body.nav_style_d.desktop_nav_static.d_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_d.desktop_nav_static.d_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .page-template {
      padding-top: 82px; }
    body.nav_style_d.desktop_nav_static.d_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="d"] {
      top: auto;
      position: absolute; }
      body.nav_style_d.desktop_nav_static.d_nav_position_below.top_bar_desktop_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="d"].active {
        top: calc(32px + 56px);
        position: fixed; }
  body.nav_style_d.desktop_nav_static.d_nav_position_above.top_bar_desktop_on.top_bar_static {
    padding-top: calc(56px + 82px); }
    body.nav_style_d.desktop_nav_static.d_nav_position_above.top_bar_desktop_on.top_bar_static .top-bar {
      top: 0; }
    body.nav_style_d.desktop_nav_static.d_nav_position_above.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="d"] {
      top: calc(56px);
      position: absolute; }
  body.nav_style_d.desktop_nav_fixed.d_nav_position_above.top_bar_desktop_on.top_bar_static {
    padding-top: calc(56px + 82px); }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_above.top_bar_desktop_on.top_bar_static .top-bar {
      top: 0; }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_above.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="d"] {
      top: calc(56px);
      position: absolute; }
      body.nav_style_d.desktop_nav_fixed.d_nav_position_above.top_bar_desktop_on.top_bar_static .desktop-navigation[data-style="d"].active {
        position: fixed;
        top: 0; }
  body.nav_style_d.desktop_nav_fixed.d_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar {
    padding-top: calc(32px + 56px + 82px); }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_d.desktop_nav_fixed.d_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="d"] {
      top: calc(32px + 56px);
      position: absolute; }
      body.nav_style_d.desktop_nav_fixed.d_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="d"].active {
        position: fixed;
        top: 32px; }
  body.nav_style_d.desktop_nav_static.d_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar {
    padding-top: calc(32px + 56px); }
    body.nav_style_d.desktop_nav_static.d_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_d.desktop_nav_static.d_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .page-template {
      padding-top: 82px; }
    body.nav_style_d.desktop_nav_static.d_nav_position_above.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="d"] {
      top: auto;
      position: absolute; }
  body.nav_style_d.desktop_nav_static.d_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar {
    padding-top: calc(32px + 56px); }
    body.nav_style_d.desktop_nav_static.d_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_d.desktop_nav_static.d_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .page-template {
      padding-top: 82px; }
    body.nav_style_d.desktop_nav_static.d_nav_position_below.top_bar_desktop_on.top_bar_static.admin-bar .desktop-navigation[data-style="d"] {
      top: auto;
      position: absolute; } }

@media (max-width: 1024px) {
  body.desktop_nav_fixed .desktop-navigation[data-style="d"] {
    position: absolute; }
    body.desktop_nav_fixed .desktop-navigation[data-style="d"].active {
      position: fixed; }
  body.nav_style_d {
    padding-top: calc(100px); }
    body.nav_style_d .desktop-navigation[data-style="d"] {
      top: 0; }
  body.nav_style_d.admin-bar {
    padding-top: calc(100px + 32px); }
    body.nav_style_d.admin-bar .desktop-navigation[data-style="d"] {
      top: calc(32px); }
  body.nav_style_d.top_bar_mobile_on.top_bar_static {
    padding-top: calc(100px + 56px); }
    body.nav_style_d.top_bar_mobile_on.top_bar_static .top-bar {
      top: 0; }
    body.nav_style_d.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="d"] {
      top: calc(56px); }
      body.nav_style_d.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="d"].active {
        top: 0; }
  body.nav_style_d.top_bar_mobile_on.top_bar_fixed {
    padding-top: calc(100px + 56px); }
    body.nav_style_d.top_bar_mobile_on.top_bar_fixed .top-bar {
      top: 0; }
    body.nav_style_d.top_bar_mobile_on.top_bar_fixed .desktop-navigation[data-style="d"] {
      top: calc(56px); }
  body.nav_style_d.admin-bar.top_bar_mobile_on.top_bar_static {
    padding-top: calc(100px + 32px + 56px); }
    body.nav_style_d.admin-bar.top_bar_mobile_on.top_bar_static .top-bar {
      top: 32px; }
    body.nav_style_d.admin-bar.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="d"] {
      top: calc(32px + 56px); }
      body.nav_style_d.admin-bar.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="d"].active {
        top: 32px; }
  body.nav_style_d.admin-bar.top_bar_mobile_on.top_bar_fixed {
    padding-top: calc(100px + 32px + 56px); }
    body.nav_style_d.admin-bar.top_bar_mobile_on.top_bar_fixed .top-bar {
      top: 32px; }
    body.nav_style_d.admin-bar.top_bar_mobile_on.top_bar_fixed .desktop-navigation[data-style="d"] {
      top: calc(32px + 56px); }
  body.nav_style_d.desktop_nav_fixed.top_bar_mobile_on.top_bar_fixed.admin-bar {
    padding-top: calc(100px + 32px + 56px); }
    body.nav_style_d.desktop_nav_fixed.top_bar_mobile_on.top_bar_fixed.admin-bar .top-bar {
      top: 32px; }
    body.nav_style_d.desktop_nav_fixed.top_bar_mobile_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="d"] {
      top: calc(32px + 56px); }
      body.nav_style_d.desktop_nav_fixed.top_bar_mobile_on.top_bar_fixed.admin-bar .desktop-navigation[data-style="d"].active {
        top: calc(32px + 56px); }
  body.nav_style_d.desktop_nav_fixed.top_bar_mobile_on.top_bar_fixed {
    padding-top: calc(100px + 56px); }
    body.nav_style_d.desktop_nav_fixed.top_bar_mobile_on.top_bar_fixed .top-bar {
      top: 0; }
    body.nav_style_d.desktop_nav_fixed.top_bar_mobile_on.top_bar_fixed .desktop-navigation[data-style="d"] {
      top: calc(56px); }
      body.nav_style_d.desktop_nav_fixed.top_bar_mobile_on.top_bar_fixed .desktop-navigation[data-style="d"].active {
        top: calc(56px); } }

@media (max-width: 782px) {
  body.desktop_nav_fixed .desktop-navigation[data-style="d"].active {
    position: fixed; }
  body.nav_style_d {
    padding-top: calc(100px); }
    body.nav_style_d .desktop-navigation[data-style="d"] {
      position: absolute;
      top: 0; }
  body.nav_style_d.admin-bar {
    padding-top: calc(100px + 46px); }
    body.nav_style_d.admin-bar .desktop-navigation[data-style="d"] {
      top: calc(46px); }
  body.nav_style_d.top_bar_mobile_on.top_bar_static {
    padding-top: calc(100px + 56px); }
    body.nav_style_d.top_bar_mobile_on.top_bar_static .top-bar {
      top: 0; }
    body.nav_style_d.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="d"] {
      top: calc(56px); }
      body.nav_style_d.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="d"].active {
        top: 0; }
  body.nav_style_d.top_bar_mobile_on.top_bar_fixed {
    padding-top: calc(100px + 56px); }
    body.nav_style_d.top_bar_mobile_on.top_bar_fixed .top-bar {
      top: 0; }
    body.nav_style_d.top_bar_mobile_on.top_bar_fixed .desktop-navigation[data-style="d"] {
      top: calc(56px); }
  body.nav_style_d.admin-bar.top_bar_mobile_on.top_bar_static {
    padding-top: calc(100px + 46px + 56px); }
    body.nav_style_d.admin-bar.top_bar_mobile_on.top_bar_static .top-bar {
      top: 46px; }
    body.nav_style_d.admin-bar.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="d"] {
      top: calc(46px + 56px); }
      body.nav_style_d.admin-bar.top_bar_mobile_on.top_bar_static .desktop-navigation[data-style="d"].active {
        top: 46px; }
  body.nav_style_d.admin-bar.top_bar_mobile_on.top_bar_fixed {
    padding-top: calc(100px + 46px + 56px); }
    body.nav_style_d.admin-bar.top_bar_mobile_on.top_bar_fixed .top-bar {
      top: 46px; }
    body.nav_style_d.admin-bar.top_bar_mobile_on.top_bar_fixed .desktop-navigation[data-style="d"] {
      top: calc(46px + 56px); } }
